/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --store-locator-search-filter-background: var(--primary-light-color);
}

.StoreLocatorSearch {
    background-color: var(--store-locator-search-filter-background);
    padding: var(--store-locator-content-padding);

    .FieldForm-Fields {
        margin: 0;
    }

    .Field {
        margin-top: 0;
        font-size: var(--store-locator-content-font-size);

        input {
            width: 100%;
        }

        &.Field_isValid {
            input {
                border: none;
            }
        }
    }

    &-Button {
        position: absolute;
        height: 2.5rem;
        right: 3.5rem;
        top: 3.6rem;
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .ChevronIcon {
            font-size: var(--store-locator-content-font-size);
        }
    }
}
