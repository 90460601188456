/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --kiyoh-score-percentage: 0%;
    --kiyoh-snippet-font-size: 1.3rem;
    --kiyoh-snippet-rating-stars-background: #979797;
    --kiyoh-snippet-rating-stars-filled-background: gold;
    --kiyoh-snippet-rating-stars-size: 1.8rem;
    --kiyoh-snippet-reviews-darken-color: var(--secondary_base_color);
    --kiyoh-snippet-reviews-lighten-color: var(--primary-light-color);
    --kiyoh-snippet-total-score-background: #ED1941;
    --kiyoh-snippet-total-score-color: white;
}

.KiyohSnippet {
    display: flex;

    &-Left {
        text-align: center;
    }

    &-Right {
        display: flex;
        align-items: center;
        padding-left: .7rem;
    }

    &-Reviews {
        width: auto;
        max-width: 140px;
        color: var(--kiyoh-snippet-reviews-lighten-color);
        font-size: var(--kiyoh-snippet-font-size);

        a,
        a:visited {
            color: var(--kiyoh-snippet-reviews-lighten-color);
            text-decoration: underline;
            font-style: italic;
        }
    }

    &-RatingStars {
        display: inline-block;
        font-size: var(--kiyoh-snippet-rating-stars-size);
        letter-spacing: 2px;
        line-height: 20px;

        &::before {
            content: '★★★★★';
            background:
                linear-gradient(
                    90deg,
                    var(--kiyoh-snippet-rating-stars-filled-background) var(--kiyoh-score-percentage),
                    var(--kiyoh-snippet-rating-stars-background) var(--kiyoh-score-percentage)
                );
    
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &-TotalScore {
        background-color: var(--kiyoh-snippet-total-score-background);
        color: var(--kiyoh-snippet-total-score-color);
        display: flex;
        width: 35px;
        height: 35px;
        font-size: var(--kiyoh-snippet-font-size);
        border-radius: 50%;
        justify-content: center;
        align-items: center;
    }

    &-BestRating {
        display: none;
    }
}