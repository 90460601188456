/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --gift-card-input-gap: 1rem;
    --gift-card-imagewrapper-padding: var(--content-block-content-padding);
    --gift-card-imagewrapper-margin: -2rem -2rem 0;
    --gift-card-toggle-button: 1.4rem 0;
    --gift-card-title-margin-bottom: 1.8rem;

    --gift-card-content-block-padding: 3rem;
    --gift-card-content-block-box-shadow: 0 0 5px rgba(0,0,0,0.1);
    --gift-card-heading-font-size: 1.6rem;

    @include mobile {
        --gift-card-content-block-padding: 2.5rem 2rem;
        --gift-card-heading-font-size: 1.4rem;
    }
}

.GiftCard {
    &-ContentBlock {
        height: auto;
        box-shadow: var(--gift-card-content-block-box-shadow);
        padding: var(--gift-card-content-block-padding);

        @include mobile {
            order: 2;
            margin-top: 0;
        }

        .FieldForm-Fields {
            margin-bottom: 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: var(--gift-card-input-gap);
        }

        .FieldForm-Fields {
            margin-bottom: var(--gift-card-input-gap);

            .Field {
                margin: 0;

                &_type_textarea {
                    grid-column: 1 / 3;
                }

                > * {
                    width: 100%;
                    height: 100%;
                }

                &-Message {
                    display: none;
                }
            }
        }
    }

    &-FormActions {
        margin-bottom: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--gift-card-input-gap);

        .Button {
            padding: var(--gift-card-toggle-button);
        }
    }

    &-Heading {
        margin-bottom: var(--gift-card-title-margin-bottom);
        font-size: var(--gift-card-heading-font-size)
    }

    &-ImageWrapper {
        z-index: 10;
        padding: var(--gift-card-imagewrapper-padding);
        margin: var(--gift-card-imagewrapper-margin);
        
        background-color: white;
    }

    &-Image {
        height: 100%;

        .Image-Image {
            position: relative;
            object-fit: contain;
            object-position: center;
        }
    }
}