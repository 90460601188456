/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --store-detail-usp-title-span-color: var(--primary-dark-color);
    --store-detail-usp-item-margin-bottom: 2rem;
    --store-detail-usp-item-color: #272323;
    --store-detail-usp-icon-color: var(--primary-base-color);
    --store-detail-usp-icon-font-size: 4rem;
}

.StoreDetailUsp {
    .SiteUsp {
        &-Title {
            span {
                color: var(--store-detail-usp-title-span-color);
            }
        }

        &-List {
           flex-direction: column; 
        }
    }

    .SiteUsp {
        &-Item {
            margin-bottom: var(--store-detail-usp-item-margin-bottom);
            color: var(--store-detail-usp-item-color);
        }

        &-Icon {
            font-size: var(--store-detail-usp-icon-font-size);
            color: var(--store-detail-usp-icon-color);
        }
    }
}