/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-button-height: var(--saas-header-menu-main-item-height);
    --header-menu-menu-banner-height: 100px;
    --header-menu-main-item-margin-right-after-tablet: 4%;
    --header-menu-link-font-weight: bold;
    --header-menu-link-text-transform: normal;
    --header-menu-item-link-color: var(--primary-base-color);
}

.MenuAdvanced {
    &-Wrapper {
        display: flex;
        align-items: center;
        margin: auto;
        max-width: var(--content-wrapper-width);
        position: relative;
    }

    &-MainItemList {
        display: flex;
        align-items: center;

        @include desktop {
            height: 100%;
        }
    }

    &-MainItem {
        @include reset-list-item;

        @include desktop {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:last-of-type {
            margin: 0;
        }

        @include tablet-landscape {
            margin-right: var(--header-menu-main-item-margin-right-after-tablet);
        }

        @include after-tablet-landscape {
            margin-right: var(--header-menu-main-item-margin-right-after-tablet);
        }
    }

    &-Link {
        text-transform: var(--header-menu-link-text-transform);
        font-size: 10px;
        font-weight: var(--header-menu-link-font-weight);

        @include desktop {
            font-size: calc(10px + 2 * ((100vw - 320px) / 1260));
            display: flex;
            align-items: center;
            height: 100%;
        }

        @include max-width-view {
            font-size: 12px;
        }

        &:hover,
        &_isActive {
            color: var(--header-menu-item-link-color);
            text-decoration: none;
        }

        &:visited {
            text-decoration: none;
        }
    }

    &-Overlay {
        background-color: var(--header-menu-overlay-background-color);
        top: 100%;
        border: 1px solid var(--header-menu-overlay-border-color);
        width: 100%;
        left: 0;

        @include mobile {
            position: fixed;
            inset: 0;
            height: auto;
            padding-bottom: 0;
            border: 0;
            overflow: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }
    }

    &-MenuBanner {
        height: var(--header-menu-menu-banner-height);
    }
}