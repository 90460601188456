/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    /**
    * -------- Saas header root variables
    * Todo: When theming module is ready, change/refactor variables and use the --imported_ variant
    */
    --saas-header-wrapper-height:
        calc(
            var(--saas-header-bottom-height)
            + var(--saas-header-currency-switcher-height)
            + var(--saas-header-nav-height)
            + var(--saas-header-usps-total-height)
        );
    --saas-header-wrapper-z-index: 10;
    --saas-header-total-height:
        calc(
            var(--saas-header-wrapper-height)
            + var(--store-notice-height)
        );

    --saas-header-currency-switcher-height: 24px;
    --saas-header-background-color: var(--header-background-color);
    --saas-header-icons-color: var(--header-icons-color);
    --saas-header-bottom-height: 54px;
    --saas-header-bottom-background: var(--header-search-background-color-mobile);

    --saas-header-text-color: var(--saas-header-color);

    /* Used for downscaling logo on mobile */
    --saas-header-logo-reduction-factor: 0.72;
    --saas-header-logo-wrapper-height:
        calc(
            var(--header-logo-height)
            * var(--saas-header-logo-reduction-factor)
        );
    --saas-header-logo-wrapper-width:
        calc(
            var(--header-logo-width)
            * var(--saas-header-logo-reduction-factor)
        );

    --saas-header-menu-main-item-height: 0px;
    --saas-header-menu-min-height: 0px;

    /* -- Saas header Navigation -- */
    --saas-header-nav-links-width: 50rem;
    --saas-header-nav-display: flex;
    --saas-header-nav-links-position: absolute;
    --saas-header-nav-links-max-width: 100px;
    --saas-header-nav-logo-position-desktop: absolute;
    --saas-header-nav-logo-left-desktop: 15px;
    --saas-header-nav-logo-transform-desktop: translateY(-50%);
    --saas-header-nav-menu-grid-column-desktop: auto;
    --saas-header-nav-padding-x: 10px;
    --saas-header-nav-padding-y: 15px;
    --saas-header-nav-height:
        calc(
            var(--saas-header-logo-wrapper-height)
            + calc(var(--saas-header-nav-padding-y) * 2)
        );

    /* -- Saas header navigation menu -- */
    --saas-header-nav-menu-position-desktop: absolute;
    --saas-header-nav-menu-padding-y: 0px;
    --saas-header-nav-menu-padding-x: 0px;
    --saas-header-nav-menu-padding-left: calc(var(--header-logo-width) + 40px);

    /* -- Saas header USPs -- */
    --saas-header-usps-display: flex;
    --saas-header-usps-mobile-display: block;
    --saas-header-usps-background: var(--usps-background-color);
    --saas-header-usps-height: 30px;
    --saas-header-usps-padding-x: 0px;
    --saas-header-usps-padding-y: 10px;
    --saas-header-usps-total-height:
        calc(
            var(--saas-header-usps-height)
            + calc(var(--saas-header-usps-padding-y))
        );

    @at-root .hasNoUsp {
        --saas-header-usps-total-height: 0px;
    }

    @at-root .hasNoCurrencySwitcher {
        --saas-header-currency-switcher-height: 0px;
    }

    @at-root .isScrolled {
        --saas-header-usps-display: none;
        --saas-header-usps-mobile-display: none;
    }

    @include desktop {
        --saas-header-bottom-height: 0px; /* Desktop doesn't have a saas header bottom */
        --saas-bottom-header-padding-x: 3rem;
        --saas-header-wrapper-z-index: 100;
        --saas-header-menu-main-item-height: var(--saas-header-logo-wrapper-height);
        --saas-header-nav-links-max-width: none;
        --saas-header-nav-links-position: relative;
        --saas-header-logo-wrapper-height: var(--header-logo-height);
        --saas-header-logo-wrapper-width: var(--header-logo-width);

        --saas-header-nav-padding-x: 15px;
        --saas-header-usps-height: 40px;
    }
}

@mixin button-invisible {
    opacity: 0;
    padding: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
}

@mixin button-visible {
    opacity: 1;
    width: 35px;
    pointer-events: all;
    overflow: visible;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.SaasHeader {
    width: 100%;
    background-color: var(--saas-header-background-color);
    padding-top: env(safe-area-inset-top, 0);
    pointer-events: auto;
    color: var(--saas-header-text-color);

    .isScrolled & {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    @include mobile {
        z-index: 999;
    }

    &-Wrapper {
        height: var(--saas-header-wrapper-height);
        left: 0;
        position: sticky;
        right: 0;
        top: var(--store-notice-sticky-height);
        z-index: var(--saas-header-wrapper-z-index);
        pointer-events: none;
        margin-bottom: var(--store-notice-sticky-height);

        @include mobile {
            .isCheckout & {
                position: static;
            }
        }
    }

    &-CurrencySwitcher {
        min-height: var(--saas-header-currency-switcher-height);
        margin: auto;
        max-width: var(--content-wrapper-width);
        display: flex;
        justify-content: end;
        z-index: 11;
    }

    &-NavLinks {
        width: var(--saas-header-nav-links-width);
    }

    &-Nav {
        display: var(--saas-header-nav-display);
        margin: auto;
        max-width: var(--content-wrapper-width);
        min-height: calc(var(--saas-header-logo-wrapper-height) + calc(var(--saas-header-nav-padding-y) * 2));
        padding: var(--saas-header-nav-padding-y) var(--saas-header-nav-padding-x);
        align-items: center;

        &_isLogoCenter {
            --saas-header-nav-display: grid;
            --saas-header-nav-links-position: absolute;
            --saas-header-nav-logo-left-desktop: 0px;
            --saas-header-nav-logo-transform-desktop: none;
            --saas-header-nav-logo-position-desktop: static;
            --saas-header-nav-menu-grid-column-desktop: 40% 60%;
            --saas-header-nav-menu-padding-left: 0px;
        }

        &Menu {
            z-index: 9;
            min-height: var(--saas-header-menu-main-item-height);
            position: absolute;
            max-width: 75px;
            height: 100%;
            width: 100%;

            @include mobile {
                left: 0;
            }

            @include desktop {
                position: var(--saas-header-nav-menu-position-desktop);
                max-width: none;
                display: grid;
                grid-template-columns: var(--saas-header-nav-menu-grid-column-desktop);
                padding-left: var(--saas-header-nav-menu-padding-left);
            }
        }

        &Logo {
            width: var(--saas-header-logo-wrapper-width);
            margin: auto;

            @include mobile {
                height: 35px;
            }

            @include desktop {
                z-index: 10;
                position: var(--saas-header-nav-logo-position-desktop);
                left: var(--saas-header-nav-logo-left-desktop);
                top: 50%;
                transform: var(--saas-header-nav-logo-transform-desktop);
            }
        }

        &Links {
            display: flex;
            position: var(--saas-header-nav-links-position);
            right: 0;
            z-index: 4;
            align-items: center;
            order: 2;
            margin-left: auto;
            max-width: var(--saas-header-nav-links-max-width);

            @include mobile {
                &_isShowStoreSwitcher {
                    max-width: calc(var(--saas-header-nav-links-max-width) + 25px);
                }
            }

            @include desktop {
                z-index: 10;
                min-height: var(--saas-header-logo-wrapper-height);
            }

            .SearchField {
                @include mobile {
                    display: none;
                }
            }
        }
    }

    &-LogoWrapper {
        height: var(--saas-header-logo-wrapper-height);
        width: var(--saas-header-logo-wrapper-width);
        display: block;
        margin: auto;
        transition-property: opacity;
        transition-duration: 200ms;

        @include mobile {
            --saas-header-logo-wrapper-height: 35px;
        }

        @include tablet {
            --saas-header-logo-wrapper-height: 50px;
        }

        .Image-Image {
            object-fit: contain;
        }
    }

    &-Bottom {
        background-color: var(--saas-header-bottom-background);
        padding: 1rem 1.5rem;

        &Content {
            padding: 0;
        }
    }

    &_name {
        &_cart {
            .Header-Button_type_minicart {
                @include desktop {
                    --header-color: var(--primary-base-color);
                }
            }
        }
    }

    &-Button {
        @include button-invisible;

        cursor: pointer;
        margin: 0;
        height: 35px;

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu {
                @include desktop {
                    @include button-visible;
                }
            }

            &_account {
                @include button-visible;

                padding-top: 0.3rem;

                svg {
                    path {
                        fill: var(--saas-header-icons-color);
                    }
                }

                &.Header-Button_isVisible {
                    overflow: hidden;
                }
            }

            &_close {
                // @include close-button;

                @include mobile {
                    top: 6rem;
                    right: .8rem;
                    z-index: 2;
                    position: absolute;
                }
            }

            &_back {
                // @include back-button;

                z-index: 2;
                position: absolute;
                right: 1rem;
                top: 8rem;
            }

            &_menu {
                // @include menu-button;

                @include desktop {
                    width: 100px;
                }
            }

            &_minicart {
                @include button-visible;

                cursor: default;
                height: auto;
                width: auto;

                @include desktop {
                    margin-left: 2rem;
                }
            }

            &_searchClear {
                // @include clear-search-button;
            }

            &_clear {
                // @include clear-button;
            }

            &_edit {
                text-transform: uppercase;

                &.SaasHeader-Button_isVisible {
                    width: 70px;
                    opacity: .5;
                }
            }

            &_share {
                // @include share-button;

                &.SaasHeader-Button_isVisible {
                    height: 0;
                    width: 0;
                    margin-left: 1rem;
                    opacity: .5;
                }
            }

            &_home {
                // @include home-icon;
            }

            &_ok,
            &_cancel {
                font-size: 1.4rem;
                opacity: .5;

                &.SaasHeader-Button_isVisible {
                    width: 70px;
                }
            }

            &_compare {
                @include button-visible;

                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &-MyAccount {
        margin-right: 1rem;
        z-index: 1;

        @include desktop {
            margin-right: 0;
            margin-left: 2rem;
        }

        &Wrapper {
            align-items: center;
            display: flex;

            &_isActive {
                @include mobile {
                    svg {
                        path {
                            fill: var(--primary-base-color);
                        }
                    }
                }
            }
        }

        &Title {
            cursor: pointer;
            padding-right: .48rem;
            text-transform: uppercase;
            color: var(--header-color);

            @include mobile {
                padding-right: .56rem;
                display: none;
            }
        }
    }

    &-Minicart {
        &ButtonWrapper {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 26px;
            max-height: var(--saas-header-logo-wrapper-height); /* if logo height is less than 26px */

            @include tablet-portrait {
                margin-left: 0;
            }

            svg {
                path {
                    fill: var(--saas-header-icons-color);
                }
            }
        }

        &Title {
            color: var(--header-color);
            margin-right: .84rem;
            text-transform: uppercase;

            @include mobile {
                margin-right: .98rem;
            }
        }

        &Icon {
            @include desktop {
                height: 20px;
                width: 20px;
                top: 1px;
            }
        }

        &ItemCount {
            width: 2rem;
            height: 2rem;
            background: var(--primary-base-color);
            border-radius: 50%;
            color: var(--header-background);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            position: absolute;
            z-index: 2;

            @include desktop {
                right: -1rem;
                top: -1rem;
            }

            @include mobile {
                right: -0.5rem;
                top: -0.5rem;
            }
        }
    }

    &-Title {
        opacity: 0;
        max-width: 0;
        transition-property: opacity;
        transition-duration: 200ms;
        text-align: center;
        font-size: 1.56rem;
        color: var(--header-color);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 1.8rem;
        line-height: 1.8rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include mobile {
            font-size: 1.82rem;
            height: 2.1rem;
            line-height: 2.1rem;
        }

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 180px);
            }
        }
    }

    &-Usps {
        background-color: var(--saas-header-usps-background);
        min-height: var(--saas-header-usps-total-height);
        max-height: var(--saas-header-usps-total-height);
        padding: var(--saas-header-usps-padding-y) var(--saas-header-usps-padding-x);
        display: var(--saas-header-usps-display);

        &_isSticky {
            --saas-header-usps-display: flex;
        }

        .ContentWrapper {
            @include after-tablet-landscape {
                width: 100%;
                display: grid;
                grid-template-columns: auto 180px;
            }
        }
    }

    &-Kiyoh {
        @include desktop {
            display: flex;
            margin-left: auto;
        }
    }

    &-MobileUsps {
        display: var(--saas-header-usps-mobile-display);
        min-height: var(--saas-header-usps-total-height);
        padding: var(--saas-header-usps-padding-y) var(--saas-header-usps-padding-x);

        &_isSticky {
            --saas-header-usps-mobile-display: block;
        }

        .swiper {
            &-container {
                margin: 0;
                height: 100%;
            }

            &-wrapper {
                height: 100%;
            }
        }
    }

    &-Switcher {
        margin-left: 2.5rem;

        @include mobile {
            margin-left: 0;
        }
    }
}
