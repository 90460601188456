:root {
    --button-background: #a7ad54 !important;
}

.Button {
    --button-border-width: 0;
    --button-background: #a7ad54;
    --button-color: white;
    --button-height: 4rem;
    --button-hover-height: var(--button-height);
    --button-padding: 1.2rem;
    --button-hover-padding: var(--button-padding);
    --button-border-radius: 0.3rem;

    &_likeLink {
        background-color: transparent !important;
        color: var(--primary-base-color) !important;
    }

    &_isHollow {
        color: white !important;
    }
}

.CartOverlay-CartButton  {
    &.Button {
        &_isHollow {
            background:var(--button-background) !important;
            height:4rem !important;

            &:hover {
                background: #D04A0D !important;
            }
        }
    }
}

.MyAccountAddressTable,
.MyAccountAddressBook,
.FieldForm,
.MyAccountCustomerTable {
    .Button {
        &_isHollow {
            color: var(--primary-base-color) !important;
            border: 1px solid var(--primary-base-color) !important;
        }
    }
}


