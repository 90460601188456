/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --surface-price-calculation-info-background: #F9F9F9 0% 0% no-repeat padding-box;
    --surface-price-calculation-border-color: #DDDDDD;
    --surface-price-calculation-input-background: #FFFFFF 0% 0% no-repeat padding-box;
}

.SurfacePriceCalculation {    
    &-Calculation, 
    &-Info {
        background: var(--surface-price-calculation-info-background);
        border: 1px solid var(--surface-price-calculation-border-color);
        line-height: 1.8em;
        padding: 10px;
    }

    &-Info {
        font-weight: bold;
    }
    
    &-Input {
        background: var(--surface-price-calculation-input-background);
        border: solid var(--surface-price-calculation-border-color);
        border-width: 0 1px;
        padding: 10px;
        display: flex;

        @include mobile {
            display: block;
        }

        .Field {
            width: 50%;
            display: flex;
            margin-top: 0;

            @include mobile {
                width: 100%;
            }
    
            &-LabelContainer{
                padding-right: 5px;
                margin-top: 11px;

                @include mobile {
                    width: 25%;
                }
            }

            #surface {
                border-color: var(--input-border-color);
            }

            &Select {
                width: 100%;
            }
        }
    }
}

#salesOrderUnitLoss_wrapper {
    .FieldSelect-Option {
        max-height: unset;
    }
}