/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

.SalesPromotionSignUpForm {
    &-Fields {
        margin-bottom: 2.8rem;
    }

    .Field {
        margin-top: 0;
        margin-bottom: 1.1rem;

        &_type_checkbox {
            margin-bottom: 1.8rem;
        }

        @include desktop {
            display: grid;

            &:not(.Field_type_checkbox) {
                grid-template-columns: minmax(0, 220px) 1fr 1fr;
                gap: 2rem;
            }
        }

        &-Label {
            font-size: 1.4rem;

            &_isRequired {
                &::after {
                    content: '*';
                    color: var(--primary-dark-color);
                }
            }
        }
    }
}