.BannerBlock-Container {

    .Banner {

        &Item {
        
            --banner-item-link-border: 0;
            --banner-item-button-padding: 1rem;
            --banner-item-title-font-size: 1.5rem;
 
            &-Title {
                line-height: 1.1;
                font-weight: 400;
                margin: 0 0 1rem 1rem !important;
            }
        
            &-Content {
                width: 100%;
                font-style: normal;
                font-size: 1.5rem;
                line-height: 2.2rem;
                margin-bottom: 0.5rem;
                text-shadow: 0px 1px 4px #000000;
                

                &Block {
                    width: 100%;
                    padding: 1rem 2.5rem 1rem 1rem;
                    position: absolute;
                    bottom: 0;

                    @include mobile {
                            padding-right: 1.5rem;
                    }
                }
            }

            &_isSlider {
                &-Title {
                    font-size: 1.8rem;
                    font-weight: 700;
                    text-transform: none;
                    text-shadow: 0px 1px 4px #000000;
                    margin-bottom: 1rem;
                }
            
            }   
            &-Link.Button {
                font-size: 1.4rem;
                line-height: 1.8rem;
                margin: 0 auto;
                min-width: 10rem;
                
            }

            &-Subtitle {
                font-size: 2.1rem !important;
                text-shadow: 0px 1px 4px #000000;
            }

            h2,h3 {
                font-size: 3rem !important;
                line-height: 3rem;
                font-weight: 700;
                text-shadow: 0px 1px 4px #000000;
                text-align: center;
            }
        }

        &Block {
            &-Item_type_column_2 {
                .BannerItem {
                    &-ContentBlock {
                        padding-bottom: 3rem;
                    }
                }
            }
            &-Item_type_column_3 {
                min-height: 0;
                height: auto;
                padding-bottom: 66%;

                @include mobile {
                    padding-bottom: 75%;
                }

                h2 {
                    margin: 0;
                    font-size: 1.6rem;
                    line-height: 2rem;

                    @include mobile {
                        font-size: 1.2rem;
                        margin: 0 !important;

                    }
                }
            }
        }

    }

    &_column_3 {
        @include mobile {
            --banner-block-item-col: 1fr 1fr;
        }
    }
}