/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --store-notice-anchor-text-decoration: underline;
    --store-notice-background-color: var(--store-notice-bg-color);
    --store-notice-color: var(--store-notice-text-color);
    --store-notice-font-size: 1.2rem;
    --store-notice-font-weight: bold;
    --store-notice-height: 0px;
    --store-notice-text-align: center;
    --store-notice-sticky-height: 0px;
    --store-notice-z-index: 10; // same values as saas header wrapper in case of sticky

    @at-root .isNoticeVisible {
        --store-notice-height: 50px;
    }

    @at-root .isStoreNoticeSticky {
        --store-notice-sticky-height: var(--store-notice-height);
    }

    @include desktop {
        --store-notice-z-index: 100;
    }
}

.StoreNotice {
    background-color: var(--store-notice-background-color);

    &_isSticky {
        inset: 0 0 auto 0;
        position: fixed;
        z-index: var(--store-notice-z-index);
    }

    &-Wrapper {
        height: var(--store-notice-height);
        text-align: var(--store-notice-text-align);
        display: flex;
        align-items: center;
        justify-content: center;

        > *,
        ul li {
            color: var(--store-notice-color);
            font-size: var(--store-notice-font-size);
            font-weight: var(--store-notice-font-weight);
            margin: 0;

            @include mobile {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        ul {
            display: flex;

            li {
                display: flex;
                align-items: center;
                padding-right: 1rem;

                &:last-of-type {
                    padding-right: 0;

                    @include mobile {
                        display: none;
                    }
                }

                @include mobile {
                    &:nth-child(2) {
                        padding-right: 0;
                    }
                }
            }
        }

        img {
            width: auto;
            margin-right: 0.5rem;
        }

        a {
            color: inherit;
            text-decoration: var(--store-notice-anchor-text-decoration);
        }
    }
}
