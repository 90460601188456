/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --order-list-item-actions-color: #000;
    --order-list-item-divider-color: var(--expandable-content-divider-color);
    --order-list-item-background: #fff;
}

.OrderListItem {
    --header-color: var(--order-list-item-actions-color);

    border-bottom: 1px solid var(--order-list-item-divider-color);
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 8rem auto 7.2rem;
    grid-gap: 1.2rem;
    padding: 1.2rem;
    background: var(--order-list-item-background);

    @include mobile {
        padding: 1.4rem;
        min-height: 130px;
        grid-template-columns: 1fr;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 100px auto;

        // @include after-mobile {
        //     padding-bottom: 0;
        //     min-height: 6rem;
        //     align-self: center;
        // }

        @include mobile {
            grid-template-columns: 50px auto;
        }
    }

    &-Content {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr;
        align-items: center;

        > div:not(:first-of-type) {
            justify-content: center;
            text-align: center;
        }
    }

    &-Select,
    &-Actions {
        display: flex;
        align-items: center;
    }

    &-Checkbox {
        margin: 0;
    }

    &-HeadingWrapper {
        padding-left: 1.5rem;
        
        @include mobile {
            margin-right: 1rem;
        }
    }

    &-Heading {
        margin-bottom: 0;
    }

    &-Picture {
        max-width: 100px;
        align-self: center;
        padding-bottom: 100%;

        @include mobile {
            height: 100%;
        }
    }

    &-QuantityWrapper {
        display: flex;
        align-items: center;
        z-index: 9;

        @include mobile {
            margin-bottom: .7rem;
            margin-right: 1rem;
        }
    }

    &-Qty {
        margin-top: 0;
    }
    
    &-Delete {
        @include desktop {
            // @include clear-button;
        }

        // @include after-mobile {
        //     top: 1px;
        // }

        height: 35px;
        padding-left: 35px;
        font-size: 1.4rem;
        color: var(--order-list-item-actions-color);
    }

    &-Price{
        ins,
        del {
            font-size: 1.4rem;
        }
    }
}