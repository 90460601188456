/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.RangeSelector {
    .input-range {
        margin: 3rem 0rem;

        &__slider {
            margin-left: 0;
        }

        &__label {
            font-size: 1.4rem;
            
            &-container {
                left: auto;
            }

            &--value {
                top: -3rem; 
            }

            &--min,&--max {
                bottom: -2.3rem;
                right: -2.3rem;
            }
        }
    }
}