.SaasSlider-Container {
    .BannerItem {
        --banner-item-link-border: 0;
        --banner-item-button-padding: 1rem;
        --banner-item-title-font-size: 1.5rem;
        text-align: left;

        &-ContentBlock {
            position: relative;
        }
        
        &-Link.Button {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin: 0 auto;
            min-width: 10rem;
            text-align: center;
            
        }
    }
}

.SaasSliderWidget {
    
    &-Heading {
        font-weight: 600;
        font-size: 3rem !important;
        line-height: 5.5rem;
    }

    .ProductCard {
    
        &-Picture {
            transform: scale(1) !important;
            padding-bottom: 100%;
        }

        &-Price {
            margin-bottom: 1rem;

            span {
                font-size: 2rem;

            }


            .ProductPrice-SubPrice {
                display: none;
            }
        }

        &-Link {
            h2 {
                font-size: 1.8rem;
                font-weight: 300;
                color: #272323;
                
            }
        }

        &-Qty {
            display: none;
        }

        &-AddToCart {
            flex-grow: 0;
            margin: 0 auto;
            width: 50%;
            
        }
    }
}