/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  --banner-block-gap: 3rem;
  --banner-block-gap-small: 1.5rem;
  --banner-block-item-height-col-2: 45rem;
  --banner-block-item-height-col-3: 38rem;
  --banner-block-item-height-col-4: 26rem;
  --banner-block-item-height-col-5: 21rem;
  --banner-block-item-height-col-6: 18rem;
  --banner-block-item-col: 1fr;
  --banner-block-item-col-2: 1fr 1fr;
  --banner-block-item-col-3: repeat(3, 1fr);

  @include mobile {
    --banner-block-item-height-col-1: 36rem;
    --banner-block-item-height-col-2: var(--banner-block-item-height-col-1);
    --banner-block-item-height-col-3: var(--banner-block-item-height-col-1);
    --banner-block-item-height-col-4: var(--banner-block-item-height-col-1);
    --banner-block-item-height-col-5: var(--banner-block-item-height-col-1);
    --banner-block-item-height-col-6: var(--banner-block-item-height-col-1);
  }

  @include tablet {
    --banner-block-item-height-col-3: 32rem;
    --banner-block-item-height-col-1: var(--banner-block-item-height-col-3);
    --banner-block-item-height-col-2: 40rem;
    --banner-block-item-height-col-4: var(--banner-block-item-height-col-3);
    --banner-block-item-height-col-5: var(--banner-block-item-height-col-3);
    --banner-block-item-height-col-6: var(--banner-block-item-height-col-3);
  }

  @include narrow-desktop {
    --banner-block-item-height-col-6: var(--banner-block-item-height-col-4);
    --banner-block-item-height-col-5: var(--banner-block-item-height-col-3);
  }

  @include before-wide-desktop {
    --banner-block-item-height-col-6: var(--banner-block-item-height-col-4);
  }
}

.BannerBlock {
  &-Container {
    display: grid;

    &_column_1 {
      width: 100%;

      .BannerItem-ContentBlock {
        max-width: var(--content-wrapper-width);
        width: 100%;
        margin: auto auto 0 auto;
      }
    }

    &_column_2 {
      /** Todo: remove custom classes from module, and make it simple default */
      .square-images & {
        @include desktop {
          --banner-block-item-height-col-2: 30rem;
        }
      }

      grid-template-columns: var(--banner-block-item-col-2);
      gap: var(--banner-block-gap);

      @include mobile {
        grid-template-columns: var(--banner-block-item-col);
        gap: 2rem;
      }
    }

    &_column_3 {
      /** Todo: remove custom classes from module, and make it simple default */
      .square-images & {
        @include desktop {
          --banner-block-item-height-col-3: 20.6rem;
        }
      }

      grid-template-columns: repeat(3, 1fr);
      gap: var(--banner-block-gap);

      @include mobile {
        grid-template-columns: var(--banner-block-item-col);
        gap: var(--banner-block-gap-small);
      }

      @include tablet {
        gap: var(--banner-block-gap-small);
        grid-template-columns: 1fr 1fr;
      }
    }

    &_column_4 {
      grid-template-columns: repeat(4, 1fr);
      gap: var(--banner-block-gap-small);

      @include mobile {
        grid-template-columns: var(--banner-block-item-col);
        gap: 2rem;
      }

      @include tablet {
        grid-template-columns: var(--banner-block-item-col-2);
        gap: 2rem;
      }
    }

    &_column_5 {
      grid-template-columns: repeat(5, calc(20% - 12px));
      gap: var(--banner-block-gap-small);

      @include mobile {
        grid-template-columns: var(--banner-block-item-col);
        gap: var(--banner-block-gap-small);
      }

      @include tablet {
        gap: var(--banner-block-gap-small);
        grid-template-columns: var(--banner-block-item-col-2);
      }

      @include narrow-desktop {
        gap: var(--banner-block-gap);
        grid-template-columns: var(--banner-block-item-col-3);
      }
    }

    &_column_6 {
      grid-template-columns: repeat(6, calc(16.66667% - 12.5px));
      gap: var(--banner-block-gap-small);

      @include mobile {
        grid-template-columns: var(--banner-block-item-col-2);
        gap: 2rem;
      }

      @include tablet {
        gap: 2rem;
        grid-template-columns: var(--banner-block-item-col-2);
      }

      @include narrow-desktop{
        grid-template-columns: var(--banner-block-item-col-3);
      }

      @include before-wide-desktop {
        grid-template-columns: var(--banner-block-item-col-3);
      }
    }

    &_column_2,
    &_column_3,
    &_column_4,
    &_column_5,
    &_column_6 {
      .square-images & {
        @include mobile {
          --banner-block-item-height-col-2: 18rem;
          --banner-block-item-height-col-3: 18rem;
          --banner-block-item-height-col-4: 18rem;
          --banner-block-item-height-col-5: 18rem;
          --banner-block-item-height-col-6: 18rem;

          grid-template-columns: var(--banner-block-item-col-2);
          gap: 1.4rem;
        }
      }
    }
  }

  &-Item {
    &_type_column_1 {
      height: var(--banner-block-item-height-col-2);
    }

    &_type_column_2 {
      height: var(--banner-block-item-height-col-2);
    }

    &_type_column_3 {
      height: var(--banner-block-item-height-col-3);
    }

    &_type_column_4 {
      height: var(--banner-block-item-height-col-4);
    }

    &_type_column_5 {
      height: var(--banner-block-item-height-col-5);
    }

    &_type_column_6 {
      height: var(--banner-block-item-height-col-6);
    }
  }
}
