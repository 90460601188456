/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-content-border-color: #dddddd;
    --header-menu-banners-inner-padding: 2rem;
    --header-menu-content-min-height: 440px;
    --header-menu-columns-inner-padding: var(--header-menu-banners-inner-padding);
}

.MenuContent {
    &-Container {
        &_isDesktop {
            display: grid;
            min-height: var(--header-menu-content-min-height);
            background-color: var(--header-menu-overlay-background-color);
        }
    }

    &-Banners {
        display: block;
        padding: var(--header-menu-banners-inner-padding);

        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }

    &-Columns {
        display: grid;
        padding: var(--header-menu-columns-inner-padding);
        grid-template-columns: 1fr 1fr 1fr 1fr;

        &_type_mobile {
            display: block;
            padding: 0;
            background-color: var(--header-menu-overlay-background-color);
        }
    }
}
