/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-aside-border-color: #dddddd;
    --header-menu-aside-inner-padding: 2rem;
}

.MenuAside {
    &-Container {
        border-left: 1px solid var(--header-menu-aside-border-color);
        padding: var(--header-menu-aside-inner-padding);

        div {
            height: 100%;
        }
        
        &_type_BannerGroup,
        > * {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        &_type_BannerGroup {
            padding: 0;

            @include mobile {
                min-height: 400px;
            }
        }

        &-Content {
            margin: 0;

            h2 {
                font-size: 1.6rem;
                font-style: normal;
                margin-top: 0.5rem;
            }

            .ProductListPage {
                display: block;
                padding: 0;
            }

            .BannerItem-Image:not(img)::after {
                content: none;
            }

            .ProductCard > .ProductCard-Link {
                box-shadow: none;
                padding: 0;
            }
        }
    }
}