/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.AgeCheckPopup {
    @include mobile {
        align-items: flex-end;
        height: 100%;
        inset-block-start: 0;
        z-index: 205;
    }

    &-Buttons {
        display: flex;
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: center;
    }

    .Popup-CloseBtn {
        display: none;
    }
}