/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

:root {
    --product-visual-bundle-item-price-label-background-color: var(--primary-base-color);
    --product-visual-bundle-item-price-label-color: white;
    --product-visual-bundle-item-swatch-height: 114px;
    --product-visual-bundle-item-swatch-width: 99px;
    --product-visual-bundle-item-title-background-color: #f9f9f9;
    --product-visual-bundle-item-title-border: 1px solid #DDDDDD;
}

// Fix slider
@include mobile {
    .ContentWrapper.ProductPage-Wrapper {
        grid-template-columns: 100%;
    }
}

.ProductVisualBundleItem {
    &-PriceLabel {
        background-color: var(--product-visual-bundle-item-price-label-background-color);
        color: var(--product-visual-bundle-item-price-label-color);
        left: 0;
        line-height: 22px;
        padding: 0 3px;
        position: absolute;
        top: 0;
    }

    &-Selected {
        font-size: 16px;
        margin-top: 10px;
    }

    &-Swatch {
        height: var(--product-visual-bundle-item-swatch-height);
        width: var(--product-visual-bundle-item-swatch-width);
    
        .Image-Image {
            object-fit: cover;
            object-position: center;
        }
    }
    
    &-SwatchOption {
        border: 1px solid transparent;
        cursor: pointer;
        display: inline-block;
        padding: 10px;

        &_isSelected {
            border-color: var(--primary-base-color);
        }
    }

    &-SwatchOptionWrapper {
        text-align: center;
    }

    &-SwatchWrapper {
        align-items: center;

        &_isSlider {
            .swiper-button-prev {
                left: 0;
            }

            .swiper-button-next {
                right: 0;
            }
        }

        &_isStatic {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &-Title {
        background-color: var(--product-visual-bundle-item-title-background-color);
        border: var(--product-visual-bundle-item-title-border);
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
        padding: 11px;
    }

    &-Wrapper {
        margin-block-start: 24px;
    }
}