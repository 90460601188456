/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height));
    --footer-content-height: 320px;
    --footer-content-font-size: 1.2rem;
    --footer-content-font-size-desktop: 1.3rem;
    --footer-column-content-background-color: transparent;
    --footer-copyright-height: 50px;
    --footer-margin-bottom: 0;
    --footer-newsletter-button-background-color-mobile: transparent;
    
    @include mobile {
        --footer-content-font-size: 1.5rem;
    }
}

.Footer {
    $column-count: 4;

    @include mobile {
        margin-block-end: var(--navigation-tabs-height);
    }

    &-CopyrightContentWrapper {
        background-color: var(--footer-copyright-background-color);
    }

    &-CopyrightContent {
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);

        @include desktop {
            padding: 1.5rem 0;
        }
    }

    &-Copyright {
        font-size: var(--footer-content-font-size);
        text-align: center;

        @include desktop {
            font-size: var(--footer-content-font-size-desktop);
        }

        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-content-background-color);
        border-bottom: 1px solid var(--footer-content-border-color);

        .CmsBlock-Wrapper {
            font-size: 1.4rem;
            margin-bottom: 1.5rem;
        }
    }

    &-Columns {
        padding: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        text-align: center;

        @include desktop {
            flex-direction: row;
            text-align: left;
            align-items: unset;
            padding: 6rem 0; 
            justify-content: space-between;
        }

        @include tablet {
            padding-inline: 30px;
        }
    }

    &-Column {
        @include mobile {
            order: 1;
            width: 100%;

            &_isSocialBlock {
                order: 0;
            }

            &Block {
                &_isNewsletter {
                    background-color: var(--footer-column-newsletter-background-color);
                }
            }
        }
        
        &:not(:last-of-type) {
            @include desktop {
                margin-right: 12rem;
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &-ColumnTitle {
        color: var(--footer-column-title-color);
        font-size: var(--footer-column-title-font-size);
        font-weight: var(--footer-column-title-font-weight);
        margin-block-end: 15px;
        margin-bottom: 1.5rem;
        margin-top: 0;
        text-transform: var(--footer-column-title-text-transform);
        white-space: nowrap;

        @include mobile {
            margin-bottom: 0;
            padding: 1.5rem;

            @include arrow-right-icon(var(--footer-column-title-color)) {
                right: 20px;
                top: calc(var(--footer-column-title-font-size) * 1.25);
            }

            &_isActive {
                &::after {
                    transform: rotate(225deg);
                }
            }

            .Footer-Column_isSocialBlock & {
                &::after {
                    content: none;
                }
            }
            .Footer-ColumnBlock_isNewsletter & {
                color: var(--footer-column-newsletter-color);

                &::after {
                    content: none;
                }
            }
        }

        @include desktop {
            margin-block-end: 20px;
        }
    }

    &-ColumnContent {
        background: var(--footer-column-content-background-color);
        color: var(--footer-column-content-color);

        p {
            color: var(--footer-column-content-color);
        }

        @include mobile {
            display: none;
            padding: 2rem 1.5rem;

            &_isActive {
                display: block;
            }

            .Footer-ColumnBlock_isNewsletter & {
                display: block;
                background: var(--footer-column-newsletter-background-color);
                padding-top: 0;
            }
        }

        h4 {
            text-transform: uppercase;
        }

        h4,
        p,
        li {
            font-size: var(--footer-content-font-size);
        }
    }

    &-ColumnItem {
        color: inherit;
        margin-block-end: 12px;
        font-size: 14px;
        font-weight: 400;

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                width: 16px!important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }

    &-ColumnBlock {
        &_isSocial {
            @include desktop {
                margin-top: 2rem;
            }
        }

        &_isNewsletter {
            @include desktop {
                max-width: 350px;
            }
    
            .NewsletterSubscription {
                padding: 0;
    
                .FieldForm-Fields {
                    width: 100%;
                }

                @include mobile {
                    .Button {
                        background-color: var(--footer-newsletter-button-background-color-mobile);
                        border-color: var(--footer-newsletter-button-background-color-mobile);
                    }
                }
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-ColumnBlock_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }
}

.Checkout {
    + .Footer {
        @include mobile {
            --footer-margin-bottom: 12.3rem;
            margin-bottom: var(--footer-margin-bottom);
        }
    }
}

.CartPage {
    + .Footer {
        @include mobile {
            --footer-margin-bottom: 17.3rem;
            margin-bottom: var(--footer-margin-bottom);
        }
    }
}
