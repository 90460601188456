:root {
    --product-card-add-to-cart-padding: 1.2rem;
}
.Product {
    --price-with-discount-color: #000; 

    &Card {
        padding: 0 0 2rem !important;

        &-FigureReview {
            padding: 0 !important;
        }

        &-Picture {
           
            padding-bottom: 100% !important;
        }

        &-Content {
            padding: 0 2rem !important;
            
        }

        &-Name {
            margin-bottom: 0;

            h2 {
                text-transform: uppercase;
            }
        }

        &-Qty {
            display: none;
        }

        &-AddToCart {
            width: 18rem;
            border: 0;
        }

        &_listItemView_column {
            justify-content: start !important;
        }
        
    }

    &WishlistButton {
        display: none !important;
    }
}