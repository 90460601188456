/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

:root {
    --complete-order-item-border: 1px solid #DDD;
    --complete-order-item-background-color: #f9f9f9;
}

.CompleteOrderTotals {
    &-Item {
        align-items: center;
        border: {
            top: var(--complete-order-item-border);
            right: var(--complete-order-item-border);
            left: var(--complete-order-item-border);
        }
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-areas: "title price";
        margin-bottom: 0;

        @include desktop {
            grid-template-areas: "empty title price";
            grid-template-columns: 20% auto 150px;
        }

        &:first-of-type {
            border-top: 0;
        }

        &:last-of-type {
            border-bottom: var(--complete-order-item-border);
        }

        &:nth-child(odd) {
            background-color: var(--complete-order-item-background-color);
        }
    }

    &-ItemPrice {
        grid-area: price;
        padding: 2rem;
        text-align: center;
        font-weight: bold;
        font-size: 16px;

        @include desktop {
            font-size: 20px;
        }

        &_isTotal {
            color: var(--primary-dark-color);
        }
    }

    &-ItemTitle {
        grid-area: title;
        padding: 2rem;

        span {
            font-size: 16px;
        }
    }

    &-Option {
        font-size: 13px;
        font-style: italic;
        margin-bottom: 0;

        &_isQuantity {
            font-weight: bold;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}