/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-overlay-background-color: var(--dropdown-menu-background-color);
    --header-menu-mobile-overlay-background-color: var(--header-menu-overlay-background-color);
    --header-menu-overlay-border-color: #dddddd;
    --header-menu-overlay-navigation-top-position: var(--header-menu-button-height);
    --header-menu-close-button-color: #000;
    --header-menu-close-button-border-color: #000;
    --header-menu-close-button-border-background-color: #ebebeb;
    --menu-regular-banner-height: 180px;
}

.MenuRegular {
    &-Wrapper {
        display: flex;
        align-items: center;
        margin: auto;
        max-width: var(--content-wrapper-width);
        position: relative;
    }

    &-Overlay {
        top: 100%;
        border: 1px solid var(--header-menu-overlay-border-color);
        background-color: var(--header-menu-overlay-background-color) !important;
        width: 100%;
        left: 0;

        @include mobile {
            position: fixed;
            inset: 0;
            height: auto;
            padding-bottom: 0;
            border: 0;
            overflow: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }

        .MenuContent {
            &-Container {
                &_isDesktop {
                    display: block;
                }
            }

            &-Columns {
                @include desktop {
                    column-count: 4;
                    -webkit-column-count: 4;
                    display: block;
                }
            }
        }

        .MenuColumn {
            &-Container {
                page-break-inside: avoid;
                -webkit-column-break-inside: avoid;
                break-inside: avoid;
                overflow: hidden;
                margin-bottom: .5rem;
            }
        }
    }

    &-MainItemList {
        display: flex;
        align-items: center;

        @include desktop {
            height: 100%;
        }
    }

    &-MainItem {
        @include reset-list-item;

        @include desktop {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:last-of-type {
            margin: 0;
        }

        @include tablet-landscape {
            margin-right: var(--header-menu-main-item-margin-right-after-tablet);
        }

        @include after-tablet-landscape {
            margin-right: var(--header-menu-main-item-margin-right-after-tablet);
        }
    }

    &-Link {
        text-transform: var(--header-menu-link-text-transform);
        font-size: 10px;
        font-weight: var(--header-menu-link-font-weight);

        @include desktop {
            font-size: calc(10px + 2 * ((100vw - 320px) / 1260));
            display: flex;
            align-items: center;
            height: 100%;
        }

        @include max-width-view {
            font-size: 12px;
        }

        &:hover,
        &_isActive {
            color: var(--header-menu-item-link-color);
            text-decoration: none;
        }

        &:visited {
            text-decoration: none;
        }
    }

    &-Banner {
        height: var(--menu-regular-banner-height);
    }

    &-CloseButton {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        border-radius: 50%;
        width: 3.6rem;
        height: 3.6rem;
        border: 1px solid rgba(255, 255, 255, .5);
        background-color: rgba(255, 255, 255, .25);
        text-align: center;
        line-height: 3.6rem;
        color: white;
        z-index: 2;

        &::after {
            content: 'X';
            display: inline-block;
            font-size: 2rem;
            font-weight: bold;
            line-height: 3.3rem;
        }
    }

    &-PrevButton {
        width: 100%;
        text-align: left;
        padding: 1.8rem 1.8rem 1.8rem 5.5rem;
        background: var(--header-menu-navigation-prev-button-background);
        border-bottom: 1px solid var(--header-menu-navigation-prev-button-border-color);
        font-weight: bold;

        &::before,
        &::after {
            content: '';
            display: block;

            @include mobile {
                position: absolute;
            }
        }

        &::before {
            width: 25px;
            height: 2px;
            top: 28px;
            left: 18px;
            background-color: var(--header-menu-navigation-prev-button-color);
        }

        &::after {
            width: 8px;
            height: 8px;
            top: 24px;
            left: 18px;
            transform: rotate(135deg);
            border-bottom: 2px solid var(--header-menu-navigation-prev-button-color);
            border-right: 2px solid var(--header-menu-navigation-prev-button-color);
        }
    }
}
