/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  --saas-date-picker-input-height: 43px;
}

.SaasDatepicker {
    height: var(--saas-date-picker-input-height);

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker-popper {
      z-index: 3;

      &[data-placement^="bottom"] {
        @include mobile {
          margin-top: -4rem;
        }
      }
    }

    .react-datepicker__time-list-item {
      &:before {
        content: unset
      }
    }

    input {
      width: 100%;
      cursor: pointer;
    }

    .react-datepicker-popper,
    .react-datepicker {
      width: 100%;
      max-width: 30rem;

      @include mobile-s {
        max-width: 100%;
      }
    }

    .react-datepicker{
      &__tab-loop {
        margin-top: calc(var(--saas-date-picker-input-height) * -1)
      }

      &__current-month {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }

      &__month-container {
        float: unset;
        font-size: 1.3rem;
      }

      &__day-names,
      &__week {
        display: flex;
      }

      &__day-name,
      &__day {
        flex: 1;
        line-height: 3.2rem;
      }
    }

    .ChevronIcon {
      position: absolute;
      right: 12px;
      top: 14px;
    }
}
