/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-gray: #eeeeee;
    --color-light-gray: #f9f9f9;
    --color-dark-gray: #dddddd;
    --color-darkest-gray: #cccccc;
    --color-karaka: #0a0903;
    --primary-divider-color: #e0e0e0;
    --secondary-error-color: #fff;
    --primary-error-color: #d20b0b;
    --secondary-success-color: #fff;
    --primary-success-color: #2fbf71;
    --secondary-info-color: var(--color-karaka);
    --primary-info-color: #ffec51;
    --body-content-color: var(--color-black);

    // Placeholders
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    // --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    // --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    // --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    // --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    // --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    // --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});

    // TODO: BELOW IS WEBELEPHANT VERSION, DONT KNOW WHICH ONE IS LEADING/BETTER
    // Declare imported primary colors to change the theme's colors
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
    --primary-error-color: var(--imported_primary_error_color, #{$default-primary-error-color});
    --secondary-error-color: var(--imported_secondary_error_color, #{$default-secondary-error-color});

    // Header variables
    --header-search-background-color-mobile:    var(--imported_header_search_background_color_mobile,         #{$default-header-search-background-color-mobile});
    --header-background-color:                  var(--imported_header_background_color,                       #{$default-header-background-color});
    --header-icons-color:                       var(--imported_header_icons_color,                            #{$default-header-color});
    --header-color:                             var(--imported_header_color,                                  #{$default-header-color});

    // USPS variables
    --usps-background-color:                    var(--imported_usps_background_color,         #{$default-usps-background-color});
    --usps-icon-color:                      var(--imported_usps_icon_color,                    #{$default-usps-icon-color});
    --usps-text-color:                      var(--imported_usps_color,                    #{$default-usps-color});

    // Menu variables
    --bottom-menu-background-color:                    var(--imported_menu_background_color,         #{$default-menu-background-color});
    --bottom-menu-color:                               var(--imported_menu_color,                    #{$default-menu-color});

    // Dropdown Menu variables
    --dropdown-menu-background-color:                    var(--imported_dropdown_menu_background_color,         #{$default-dropdown-menu-background-color});
    --dropdown-menu-color:                               var(--imported_dropdown_menu_color,                    #{$default-dropdown-menu-color});

    // Store Notice variables
    --store-notice-bg-color:            var(--imported_store_notice_background_color,         #{$default-store-notice-background-color});
    --store-notice-text-color:                       var(--imported_store_notice_color,                    #{$default-store-notice-color});

    // Footer variables
    --footer-column-content-color:          var(--imported_footer_column_content_color,         #{$default-footer-column-content-color});
    --footer-column-title-color:            var(--imported_footer_column_title_color,           #{$default-footer-column-title-color});
    --footer-content-background-color:      var(--imported_footer_content_background_color,     #{$default-footer-content-background-color});
    --footer-content-border-color:          var(--imported_footer_content_border_color,         #{$default-footer-content-border-color});
    --footer-copyright-background-color:    var(--imported_footer_copyright_background_color,   #{$default-footer-copyright-background-color});

    --footer-column-title-font-size:        var(--imported_footer_column_title_font_size,       #{$default-footer-column-title-font-size});
    --footer-column-title-font-weight:      var(--imported_footer_column_title_font_weight,     #{$default-footer-column-title-font-weight});
    --footer-column-title-text-transform:   var(--imported_footer_column_title_text_transform,  #{$default-footer-column-title-text-transform});

    --footer-content-background-color-mobile:           var(--imported_footer_content_background_color_mobile,           #{$default-footer-content-background-color-mobile});
    --footer-column-content-background-color-mobile:    var(--imported_footer_column_content_background_color_mobile,    #{$default-footer-column-content-background-color-mobile});
    --footer-column-title-font-size-mobile:             var(--imported_footer_column_title_font_size_mobile,             #{$default-footer-column-title-font-size-mobile});
    --footer-column-title-text-transform-mobile:        var(--imported_footer_column_title_text_transform_mobile,        #{$default-footer-column-title-text-transform-mobile});
    --footer-column-newsletter-color-mobile:            var(--imported_footer_column_newsletter_color_mobile,            #{$default-footer-column-newsletter-color-mobile});
    --footer-column-newsletter-background-color-mobile: var(--imported_footer_column_newsletter_background_color_mobile, #{$default-footer-column-newsletter-background-color-mobile});

    @include mobile {
        --footer-content-background-color:              var(--footer-content-background-color-mobile);
        --footer-column-content-background-color:       var(--footer-column-content-background-color-mobile);
        --footer-column-title-font-size:                var(--footer-column-title-font-size-mobile);
        --footer-column-title-text-transform:           var(--footer-column-title-text-transform-mobile);
        --footer-column-newsletter-color:               var(--footer-column-newsletter-color-mobile);
        --footer-column-newsletter-background-color:    var(--footer-column-newsletter-background-color-mobile);
    }

    --fallback-font-family: 'Work Sans';
    --primary-font-family: var(--imported_google_font_primary);
}
