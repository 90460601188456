/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.SaasGoogleMaps {
    --content-block-title-padding: 1rem 1.5rem;

    div[role=dialog] {
        padding:0 !important;
        max-height: none !important;

        > * {
            max-height: none !important;
        }
    }

    width: 100%;
    height: 100%;

    &-InfoWindow {
        min-width: 280px;

        .ContentBlock {
            &-TitleLink {
                padding-right: 1.4rem;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
}