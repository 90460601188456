:root {
    --content-wrapper-width: 1360px !important;
    --h1-font-size: var(--imported_h1_font_size,30px);
}

.CmsPage {
    h1,
    h2,
    h3 {
        text-transform: unset !important;
        font-size: 1.8rem !important;
    }
}

.HomePageInfo {
    max-width: var(--content-wrapper-width);
    margin: 0 auto 2rem auto;
    padding: 0 1.5rem;

    @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
    }

    &-Column {
        margin-bottom: 2rem;
        
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2.5rem;
            margin: 0;
        }

        &_one {
            @include desktop {
                grid-template-columns: 1fr;
            }
        }
    }

    &-Block {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

        @include mobile {
            &:first-of-type {
                margin-bottom: 2rem;
            }
        }

        .HomePageInfo-Column_one & {
            padding: 3rem;

            h1 {
                text-transform: uppercase;
                font-style: italic;
                margin-bottom: 1.5rem;

                strong {
                    color: var(--secondary-base-color);
                }
            }
            
            p {
                font-size: 1.5rem;
                line-height: 3rem;
                font-weight: 300;
            }
        }

        &Title {
            padding: 1rem 2rem;
            max-height: 5rem;
            background-color: var(--secondary-light-color);
           
            h2 {
                margin: 0;
                text-transform: uppercase;
                font-size: 1.6rem;
            }
        }

        &Content {
            padding: 2rem;
            
            img {
                width: 100%;
                height: auto;
            }

            li {
                @include reset-list-item;
                line-height: 3rem;
                font-size: 1.6rem;
                display: flex;
                justify-content: flex-start;

                span {
                    font-weight: bold;
                    width: 160px;

                    + span {
                        font-weight: normal;
                    }
                }
            }

            .phone {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 1.6rem;
                margin-top: 1.8rem;
                border-top: 1px solid var(--secondary-light-color);
                font-size: 1.6rem;
                font-weight: 700;

                i {
                    margin-right: 1rem;
                    font-size: 3rem;
                   
                    color: var(--secondary-base-color);

                    &::before {
                        color: var(--primary-base-color);
                    }
                }
            }
        }
    }
}

// Maps specifieke styling, daarom geen variables
.LayoutGroupWidget-Item {
    .mapouter {
        width: 100%;
        height: 100%;
    }

    .gmap_canvas {
        height: 100%;

        iframe {

            @include desktop {
                height: 100%;
            }

            @include mobile {
                height: 35rem;
            }
        }
    }
}

@include desktop {
    .Checkout {
        margin-top: 0 !important;
    }
}

button.CheckoutAddressBook {
    &-Button {
        color: var(--hollow-button-color) !important;
    }
}

.Breadcrumbs {
    background-color: #eee !important;
}