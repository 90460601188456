/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-item-icon-background: #f9f9f9;
    --header-menu-item-icon-border-color: #dddddd;
    --header-menu-item-icon-outer-width: 110px;
    --header-menu-item-icon-outer-height: var(--header-menu-item-icon-outer-width);
    --header-menu-menu-item-background: white;
    --header-menu-menu-item-padding: 1.2rem;
    --header-menu-menu-item-color: var(--saas-header-menu-bottom-color);
    --header-menu-menu-item-active-color: var(--primary-base-color);
    --header-menu-menu-item-background-active: var(--header-menu-menu-item-background);
}

@mixin mobile-menu-item {
    width: 100%;
    text-align: left;
    padding: calc(var(--header-menu-menu-item-padding) * 1.5);
    font-size: 1.6rem;
    font-weight: bold;
    display: block;
    color: #222222;
    text-decoration: none;

    +a {
        margin-left: 0;
    }

    &:hover {
        text-decoration: none;
    }

    &::after {
        top: 25px;
        right: 20px;
    }

    &_isActive {
        &::after {
            transform: rotate(225deg);
        }
    }
}

@mixin button-is-active {
    background-color: var(--header-menu-menu-item-background-active);
    color: var(--header-menu-menu-item-active-color);

    &::after {
        border-color: var(--link-color);
    }
}

.MenuLink {
    &-Caption {
        font-size: inherit;
        font-weight: inherit;
    }

    &-IconButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }

    &-IconTitle {
        font-size: 1.6rem;
        font-weight: bold;
    }

    &-Icon {
        border-radius: 50%;
        border: 1px solid var(--header-menu-item-icon-border-color);
        background: var(--header-menu-item-icon-background);
        width: var(--header-menu-item-icon-outer-width);
        height: var(--header-menu-item-icon-outer-height);
        margin-bottom: 1.5rem;
        background: {
            size: calc(var(--header-menu-item-icon-outer-width) * 0.5) calc(var(--header-menu-item-icon-outer-height) * 0.5);
            position: center;
            repeat: no-repeat;
        }
    }

    &-Item {
        padding: var(--header-menu-menu-item-padding);
        cursor: pointer;

        @include arrow-right-icon(var(--header-menu-menu-item-color)) {
            right: 15px;
            top: 20px;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                @include button-is-active;
            }
        }

        &_isActive {
            @include button-is-active;
        }
        @include mobile {
            @include mobile-menu-item;
        }
    }

    &-Link {
        color: var(--header-menu-menu-item-color);

        &.HeaderMenu-ColumnTitle {
            @include mobile {
                display: block;
                @include mobile-menu-item;
            }
        }
    }

    &-Title {
        margin-bottom: .4rem;
        font-size: 1.6rem;
        font-weight: bold;
    }

    &-Figure {
        margin-bottom: .4rem;
        font-size: 1.6rem;
        font-weight: bold;
    }
}
