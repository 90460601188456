.BannerItem {
    --banner-item-title-font-size: 2rem;
    --banner-item-button-padding: 1.2rem;
    --banner-item-link-border: 0;
    text-align: center;

    &-Title {
        text-align: center !important;
    }
}
