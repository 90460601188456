.SiteUsps {
    --site-usp-icon-color: black;
    --site-usp-font-size: 1.3rem;
    background-color: #eee;
    margin-bottom: var(--content-wrapper-margin-bottom);
    
    .block-static-block {
        width: 100%;
        max-width: var(--content-wrapper-width);
        margin: -3rem auto 0 auto;
        display: flex;
        justify-content: center;
        height: 5rem;

        @include desktop {
            padding: 0 2rem;
        }
            
        @include mobile {
            display: block;        
        }
    
        li {
            color: black;
        }
        
        .SiteUsp {
            &-Item {
                --site-usp-icon-color: #000;

                @include mobile {
                    text-align: center;
                    display: none;       
                    
                    &:first-of-type {
                        display: block;
                    }
                }

                @include desktop {
                    &:not(:last-of-type) {
                        margin-right: 3rem;
                    }
                }
            }

            &-Content {
                --site-usp-font-size: 1.4rem;
                font-style: normal !important;
                color: var(--global-link-color);
            }
        }
    }
}

.ProductActions-Usps {
    .SiteUsp {
        &-Item {
            --site-usp-icon-color: var(--primary-base-color);
        }

        &-Content {
            font-weight: 700;
        }
    }
}