/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.BlogPage {
    --blog-page-wrapper-padding: 8rem var(--content-wrapper-padding-inline, 2rem);
    --blog-page-category-list-display: grid;
    --blog-page-category-list-gap: 6rem;
    --blog-page-category-list-template-columns: repeat(2, 1fr);

    @include mobile {
        --blog-page-wrapper-padding: 1.5rem;
    }

    &-Wrapper {
        padding: var(--blog-page-wrapper-padding);
    }

    @include mobile {
        --blog-page-category-list-template-columns: 1fr;
        --blog-page-category-list-gap: 1.5rem;
    }

    &-CategoryList {
        display: var(--blog-page-category-list-display);
        grid-template-columns: var(--blog-page-category-list-template-columns);
        gap: var(--blog-page-category-list-gap);
    }
}