.CheckoutShipping {
    &-DeliveryWrapper {
        display: flex;
        flex-direction: column;
    }

    .CheckoutDeliveryOptions {
        order: 1;
    }

    .OrderDateSelect {
        order: 2;
    }
}

.CheckoutDeliveryOptions {
    .Checkout-Heading {
        margin-bottom: 5.5rem;

        &::after {
            content: 'Vul uw postcode in om te zien of wij uw bestelling ook bij u thuis bezorgen. ';
            font-size: 1.4rem;
            font-weight: 400;
            font-style: italic;
            position: absolute;
            bottom: -4.9rem;
            left: 0;
            line-height: 1.4;
        }
    }
}

.CheckoutDeliveryOption-DeliveryDate {
    display: flex;

    .SaasDatePicker {
        flex: 1;
    }

    .OrderDateSelect-DeliveryTime {
        @include mobile {
            width: 11rem;
        }
    }

    .FieldSelect {
        width: 100% !important;
    }
}