.SiteUsps {
    list-style: none;
    padding: 0;
    margin: 0;

    &-Item {
        position: relative;
        margin-bottom: 1.5rem;

        &::before {
            content: none;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 8px;
            width: 12px;
            height: 6px;
            transform: rotate(135deg) translateX(-5px);
            border-right: 2px solid var(--primary-base-color);
            border-top: 2px solid var(--primary-base-color);
        }
    }
}
