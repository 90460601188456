
.MenuRegular {

    &-Banner {
        --menu-regular-banner-height: 7rem !important;

        & .MenuBanner-Main {
            background-color: grey;
        }

    }
}