/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --min-mobile-width: 320px;
    --content-wrapper-width: 1440px;
    --content-wrapper-padding-inline: 1.4rem;
    --content-wrapper-margin-inline: auto;
    --content-wrapper-margin-bottom: 2rem;

    @include desktop {
        --content-wrapper-padding-inline: 1.5rem;
        --content-wrapper-margin-bottom: 4rem;
    }
}

.ContentWrapper {
    max-width: var(--content-wrapper-width);
    margin-inline: var(--content-wrapper-margin-inline);
    padding-inline: var(--content-wrapper-padding-inline);

    &_isRow {
        margin-bottom: var(--content-wrapper-margin-bottom);
    }

    &_isFluid {
        margin-inline: 0;
        max-width: none;
        padding: 0;
        width: 100%;
    }
}
