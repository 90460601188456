/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  @include desktop {
  --banner-slider-height: 25rem;
  --banner-slider-height-is-full-width: 45rem;
  --banner-slider-item-content-block-padding-is-full-width: 0 1.5rem 3rem 1.5rem;
  }

  @include mobile {
    --banner-slider-height: 20rem;
    --banner-slider-height-is-full-width: 30rem;
    --banner-slider-item-content-block-padding-is-full-width: 0 1.5rem 6rem 1.5rem;
  }

  --banner-slider-item-content-block-margin: auto auto 0 auto;
  --banner-slider-item-content-block-padding: 0 1.5rem 1.5rem 1.5rem;
  --banner-slider-item-title-font-size: 2.2rem;
}

.BannerGroup {
  &-Container {
    &.ContentWrapper_isFluid {
      .BannerSlider {
        &-Item {
          min-height: var(--banner-slider-height-is-full-width);
          max-height: var(--banner-slider-height-is-full-width);

          .BannerItem {
            &-ContentBlock {
              padding: var(--banner-slider-item-content-block-padding-is-full-width);
            }
          }
        }
      }
    }
  }
}

.BannerSlider {
  &-Item {
    height: var(--banner-slider-height);

    .BannerItem-Title {
      font-size: var(--banner-slider-item-title-font-size);
    }

    .BannerItem-ContentBlock {
      margin: var(--banner-slider-item-content-block-margin);
      padding: var(--banner-slider-item-content-block-padding);
    }
  }

  &-Container {
    &_type_column_1 {
      --banner-slider-height: 56rem;
    }
  }
}