.CmsPage {

    --saasbuttonwidget-button-background: #a7ad54;

    &-Content {
        h2.title {
            text-align: center;
            font-size: 4.4rem !important;
            font-weight: 900;

            @include mobile { 
                font-size: 2.1rem;
            }
        }
    }

    .HomePageInfo {

        &-BlockTitle {

            h2 {
                font-size: 1.6rem;
                font-weight: 700;
            }
        }

        &-BlockContent {
            font-size: 1.4rem;
        }
    }

    .CmsBlock {
        &-Wrapper {
            &-Inner {
                padding: 0 2rem;
                max-width: var(--content-wrapper-width);
                margin: 2rem auto;
                
                @include desktop {
                    &-Content {
                        column-count: 2;
                        column-gap: 4rem;
                    }
                }
            }
        }
    }
}

.WideBanner-Container {
    background-color: #EEE;
    margin: 4rem 0;
    padding: 2rem 0 3rem;
    
    @include mobile { 
        margin: 1rem 0;
        padding: 0.5rem 0 1.5rem;
    }

    .BannerItem {
        &-Title {
             text-align: left;
             text-transform: uppercase;
             font-weight: 700 !important;

             &::after {   
                 content: '\276F';
                 position: absolute;
                 right: 0;
                 
             }
         }
    }
}


.LayoutGroupWidget-Item {

    .CmsBlock-Wrapper {
        border: 1px solid #e6e6e6;
    }

    &:last-of-type {

        .CmsBlock-Wrapper {
            .mapouter {
                border: 1px solid #e6e6e6;
                padding: 2rem;
            }
            
            h1 {
                text-align: center;
                font-weight: 900;
                margin-bottom: 3rem;
            }
        }
    }
}
