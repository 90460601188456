/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

.ProductBrandLogo {
    margin-bottom: 10px;

    &-Label,
    &-Image {
        height: 40px;
        width: 120px;

        @include desktop {
            height: 50px;
            width: 150px;
        }

        img {
            object-position: left;
            object-fit: contain;
        }
    }
}