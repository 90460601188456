/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

.ExceedStockPopup {
  &-ContentWrapper {
    max-width: 500px;
  }

  &-Title {
    color: #bb8745;
  }

  &-Actions {
    text-align: right;
    margin-top: 3rem;

    button:first-child {
      margin-right: 1rem;
      background: transparent;
      color: black;
      border-color: black;
    }
  }
}
