/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --site-usp-icon-color: var(--usps-icon-color);
    --site-usp-icon-font-size: 1.4rem;
    --site-usp-item-color: var(--usps-text-color);
    --site-usp-font-size: 1.4rem;

    @include mobile {
        --site-usp-item-color: #222;
        --site-usp-font-size: 10px;
    }
}

.SiteUsp {
    &-List {
        display: flex;
    }

    &-Item {
        @include reset-list-item;

        color: var(--site-usp-item-color);
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
            margin-right: 5rem;
        }

        &_isSlide {
            padding: 0 1rem;
            justify-content: center;
        }
    }

    &-Icon {
        color: var(--site-usp-icon-color);
        font-size: var(--site-usp-icon-font-size);
        margin-right: 0.7rem;
    }

    &-Content {
        font-style: italic;
        font-size: var(--site-usp-font-size);
        font-weight: 600;

        @include desktop {
            font-size: calc(10px + 2 * ((100vw - 320px) / 1260));
        }
    }
}

.Header {
    &-MobileUsps {
        .swiper-wrapper {
            padding-bottom: 0;
        }
    }
}
