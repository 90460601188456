/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

:root {
    --sales-promotion-page-content-spacing: 2rem;
    --sales-promotion-page-grid-gap: 4rem;
    --sales-promotion-page-listing-gap: 2rem;
    --sales-promotion-page-sidebar-content-bg: var(--primary-base-color);
    --sales-promotion-page-sidebar-heading-bg: var(--primary-dark-color);
    --sales-promotion-page-sidebar-heading-height: 0px;
    --sales-promotion-page-sidebar-inset-top: var(--sales-promotion-page-sidebar-heading-height);
    --sales-promotion-page-sidebar-max-width: 0px;
    --sales-promotion-page-sidebar-spacing: 3rem;
    --sales-promotion-page-sidebar-text-color: white;
}

.SalesPromotionPage {
    --product-list-widget-h2-font-size: 2.8rem;
    --product-list-widget-h2-font-style: normal;
    --product-list-widget-h2-text-transform: normal;

    @include mobile {
        --product-list-widget-h2-font-size: 2.2rem;

        z-index: 1;
    }

    @include tablet {
        --product-list-widget-h2-font-size: 1.8rem;
    }

    &-Content {
        padding-top: var(--sales-promotion-page-content-spacing);

        @include desktop {
            @at-root .pageHasSidebar {
                --sales-promotion-page-sidebar-max-width: 340px;
                --sales-promotion-page-content-spacing: var(--sales-promotion-page-grid-gap);
            }

            display: grid;
    
            &_hasTwoColumns {
                padding-top: 0;
                grid-template-columns: minmax(0, var(--sales-promotion-page-sidebar-max-width)) 1fr;
                gap: var(--sales-promotion-page-grid-gap);
            }
        }
    }

    &-Heading {
        h1 {
            font-size: 2.6rem;
        }

        span {
            font-size: 1.8rem;
        }

        &_inSidebar {
            background: var(--sales-promotion-page-sidebar-heading-bg);
            padding: var(--sales-promotion-page-sidebar-spacing);

            h1, span {
                color: var(--sales-promotion-page-sidebar-text-color);
            }
        }
    }

    &-MainContent {
        padding-top: var(--sales-promotion-page-content-spacing);
    }

    &-Sidebar {
        margin-top: calc(-1 * var(--sales-promotion-page-sidebar-inset-top));
    }

    &-SidebarContent {
        background: var(--sales-promotion-page-sidebar-content-bg);
        padding: var(--sales-promotion-page-sidebar-spacing);

        &,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: var(--sales-promotion-page-sidebar-text-color);
        }

        a, a:visited {
            color: var(--sales-promotion-page-sidebar-text-color);
            text-decoration: underline;
        }
    }

    .ProductListWidget {
        padding: 0;

        &-Page {
            padding: 0;
            grid-row-gap: var(--sales-promotion-page-listing-gap);
            grid-column-gap: var(--sales-promotion-page-listing-gap);
        }
    }
}