/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.CmsPage {
    padding-top: 3rem;

    li {
        &:before {
            display: none;
        }
    }
    
    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 1.2rem);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 1.4rem);
        }
    }

    &-Content {
        .cms-content {
            @include default-list;
        }

        h2 {
            @include mobile {
                text-align: center;
            }
        }
        
        ul,
        ol {
            margin: 0 0 2rem 0;
            padding: 0 0 0 2rem;
        }

        ul {
            li {
                list-style: disc;
            }
        }

        ol {
            li {
                list-style: numeric;
            }
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 2rem;
        margin-block-start: 2rem;
        margin-block-end: var(--header-nav-height);
        margin-inline: auto;

        @include mobile {
            padding-inline: 1.4rem;
            margin-block-start: 0;
        }

        @include desktop {
            margin-block-start: 0;
        }

        &_page_width {
            /**
            * TODO: Remove this fix when page-builder is used
            */
            &_default {
                #html-body & {
                    max-width: var(--content-wrapper-width);
                    padding: 2rem;
                }
            }

            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
    }

    &-Heading {
        max-width: var(--content-wrapper-width);
        margin-block-start: 0;
        margin-block-end: 1.8rem;
        margin-inline: auto;
        width: 100%;

        @include mobile {
            margin-block-end: 2.1rem;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 1rem;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 2.2rem;
        display: block;

        @include mobile {
            margin-block-end: 2.52rem;
        }
    }

    .Slider-Crumbs {
        position: unset;
        margin-block: 1.6rem 4.0rem;

        @include mobile {
            margin-block: 2rem 2.8rem;
        }
    }

    h1,
    h2,
    h3 {
        font-size: 2.5rem;
    }

    h1,
    h2,
    h3 {
        text-align: left;
        text-transform: uppercase; //this has to be a config setting
    
        span {
            color: var(--primary-dark-color);  
        }

        em {
            font-weight: 400;
        }

        @include mobile {
            text-align: center;
            font-size: 2rem;
        }
    }

    .widget {
        overflow-x: auto;
    }
}