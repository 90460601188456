/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.CustomerNotesTextArea {
    margin-top: 2rem;
    
    .Field {
        &-Label {
            margin-bottom: 1rem;
            font-size: var(--checkout-heading-font-size);
            color: var(--checkout-heading-color);
            font-weight: var(--checkout-heading-font-weight);
        }
    }

    textarea#customer_notes {
        width: 100%;
    }
}