/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --store-detail-page-content-block-box-shadow: none;
    --store-detail-page-content-block-border: 1px solid #efefef;
}

.StoreDetailPage {
    min-height: 500px;
    
    &-NoContentWrapper {
        padding: 3rem 0 10rem 0;
    }

    .CmsBlock-Wrapper {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            span {
                color: var(--primary-dark-color);
            }
        }

        > ul li {
            list-style-type: disc;
        }
    }

    .ContentBlock {
        &_type_StoreAddress,
        &_type_StoreHours {
            --content-block-title-text-transform: uppercase;
            --content-block-title-font-size: 1.7rem;

            box-shadow: var(--store-detail-page-content-block-box-shadow);
            border: var(--store-detail-page-content-block-border);

            .ContentBlock-Title {
                padding: var(--content-block-content-padding);
                border-bottom: var(--store-detail-page-content-block-border);
            }
        }
    }
}