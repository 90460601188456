/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --cookiepopup-bg: #{$white};
    --cookiepopup-border: var(--secondary-base-color);
    --cookiepopup-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.20);
    --cookiepopup-default-color: #272323;
    --cookiepopup-default-dark-color: black;
    --cookiepopup-padding: 0.5rem 1rem;
    --cookiepopup-padding-mobile: 0.5rem 3rem;
}

.CookiePopup {
    align-items: center;
    background-color: var(--cookiepopup-bg);
    border-top: 1px solid var(--cookiepopup-border);
    box-shadow: var(--cookiepopup-box-shadow);
    display: flex;
    justify-content: center;
    padding: var(--cookiepopup-padding);
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 300;

    @include mobile {
        flex-direction: column;
        top: calc(var(--header-wrapper-height) - 30px);
        z-index: 9001;
        padding: var(--cookiepopup-padding-mobile);
    }

    &-Content {
        font-size: 1.5rem;
        margin-bottom: 0;
        padding: 0 1rem;

        @include mobile {
            font-size: 2rem;
            line-height: 1.6;
            text-align: center;
            padding: 2rem 1rem;
        }

        a {
            text-decoration: underline;
        }
    }

    &-Link {
        margin-left: 5px;
    }

    &-CTA {
        @include button;

        background-color: var(--cookiepopup-default-color);
        border-radius: 0.5rem;
        border: 0.1rem solid var(--cookiepopup-default-color);

        &:hover {
            --button-hover-border: var(--cookiepopup-default-color);
            background-color: var(--cookiepopup-default-dark-color); 
            border-color: var(--button-hover-border);
        }
    }
}
