/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-navigation-border-color: #dddddd;
    --header-menu-navigation-main-space-top: 38px;
    --header-menu-navigation-main-background: #f9f9f9;
    --header-menu-navigation-min-height: 440px;
    --header-menu-navigation-banner-height: 180px;
    --header-menu-navigation-prev-button-background: #f9f9f9;
    --header-menu-navigation-prev-button-color: #222222;
    --header-menu-navigation-prev-button-border-color: #dddddd;
}

@mixin mobile-menu-item {
    width: 100%;
    text-align: left;
    padding: calc(var(--header-menu-menu-item-padding) * 1.5);
    font-size: 1.6rem;
    font-weight: bold;
    display: block;
    color: var(--header-menu-menu-item-color);
    text-decoration: none;

    +a {
        margin-left: 0;
    }

    &:hover {
        text-decoration: none;
    }

    &::after {
        top: 25px;
        right: 20px;
    }

    &_isActive {
        &::after {
            transform: rotate(225deg);
        }
    }
}

@mixin button-is-active {
    background-color: var(--header-menu-menu-item-background-active);
    color: var(--header-menu-menu-item-active-color);

    &::after {
        border-color: var(--link-color);
    }
}

.MenuNavigation {
    &-Wrapper {
        display: grid;
        grid-template-columns: 200px auto;
        min-height: var(--header-menu-navigation-min-height);

        &_type_mobile {
            display: block;
            z-index: 1;
        }
    }

    &-DesktopView {
        background-color: var(--header-menu-navigation-main-background);
        padding-top: var(--header-menu-navigation-main-space-top);
        border-right: 1px solid var(--header-menu-navigation-border-color);

    }

    &-MobileView {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 2rem;
        border-bottom: 1px solid var(--header-menu-navigation-border-color);
    }

    &-Banner {
        height: var(--header-menu-navigation-banner-height);
    }

    &-PrevButton {
        width: 100%;
        text-align: left;
        padding: 1.8rem 1.8rem 1.8rem 5.5rem;
        background: var(--header-menu-navigation-prev-button-background);
        border-bottom: 1px solid var(--header-menu-navigation-prev-button-border-color);
        font-weight: bold;

        &::before,
        &::after {
            content: '';
            display: block;

            @include mobile {
                position: absolute;
            }
        }

        &::before {
            width: 25px;
            height: 2px;
            top: 28px;
            left: 18px;
            background-color: var(--header-menu-navigation-prev-button-color);
        }

        &::after {
            width: 8px;
            height: 8px;
            top: 24px;
            left: 18px;
            transform: rotate(135deg);
            border-bottom: 2px solid var(--header-menu-navigation-prev-button-color);
            border-right: 2px solid var(--header-menu-navigation-prev-button-color);
        }
    }

    &-MenuItem {
        padding: var(--header-menu-menu-item-padding);
        cursor: pointer;

        // @include arrow-right-icon(var(--header-menu-menu-item-color)) {
        //     right: 15px;
        //     top: 20px;
        // }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                @include button-is-active;
            }
        }

        &_isActive {
            @include button-is-active;
        }
        
        @include mobile {
            @include mobile-menu-item;
        }

        .MenuLink-Caption {
            @include desktop {
                font-size: 1.6rem;
                font-weight: bold;
            }
        }
    }

    &-CloseButton {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        border-radius: 50%;
        width: 3.6rem;
        height: 3.6rem;
        border: 1px solid rgba(255, 255, 255, .5);
        background-color: rgba(255, 255, 255, .25);
        text-align: center;
        line-height: 3.6rem;
        color: white;
        z-index: 2;

        &::after {
            content: 'X';
            display: inline-block;
            font-size: 2rem;
            font-weight: bold;
            line-height: 3.3rem;
        }
    }
}
