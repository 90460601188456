/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --store-locator-container-padding: 0;
    --store-locator-content-padding: 3rem;
    --store-locator-content-font-size: 1.6rem;
    --store-locator-description-color: white;
    --store-locator-description-background: #272323;
    --store-locator-search-filter-background: var(--primary-light-color);
    --store-locator-min-height: 12rem;
    --store-locator-min-height-mobile: 30rem;

    @include mobile {
        --store-locator-container-padding: 0 1.5rem;
    }
}

.StoreLocator {
    &-Container {
        padding: var(--store-locator-container-padding);
        min-height: 5rem;

        &_isFullWidth {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 4rem;
        }

        @include mobile {
            padding:0;
            grid-template-columns: initial;
            grid-column-gap: 4rem;
            display: flex;
            flex-direction: column;
        }
    }

    &-SearchMapContainer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: 4rem;
        margin-bottom: 3rem;

        @include mobile {
            grid-template-columns: 1fr;
        }
    }

    &-Description {
        padding: var(--store-locator-content-padding);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include mobile {
                text-align: left;
            }
        }
    }

    &-Description {
        background-color: var(--store-locator-description-background);
        color: var(--store-locator-description-color);

        p {
            color: var(--store-locator-description-color);
        }

        @include desktop {
            min-height: calc(var(--store-locator-min-height) + 10rem);

            &Container {
                min-height: var(--store-locator-min-height);
            }
        }

        * {
            font-size: var(--store-locator-content-font-size);
            margin: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &-Map {

        @include mobile {
            height: var(--store-locator-min-height-mobile);
        }

        > * {
            width: 100%;
            height: 100%;
        }
    }
}
