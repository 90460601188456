/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --blog-detail-page-post-list-display: grid;
    --blog-detail-page-post-list-gap: 4rem;
    --blog-detail-page-post-list-padding: 4rem;
    --blog-detail-page-post-list-background-color: #fff;
    --blog-detail-page-post-list-template-columns: repeat(2, 1fr);

    @include mobile {
        --blog-detail-page-post-list-gap: 1.5rem;
        --blog-detail-page-post-list-padding: 1.5rem;
        --blog-detail-page-post-list-template-columns: 1fr;
        --blog-detail-page-post-list-background-color: #F9F9F9;
    }
}

.BlogDetailPage {
    &-Wrapper {
        padding-top: var(--blog-detail-page-post-list-padding);
        background-color: var(--blog-detail-page-post-list-background-color);
    }

    &-PostList {
        display: var(--blog-detail-page-post-list-display);
        grid-template-columns: var(--blog-detail-page-post-list-template-columns);
        gap: var(--blog-detail-page-post-list-gap);
    }
}