/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
	--banner-item-min-height: 17rem;
	--banner-item-max-content-width: 80rem;
	--banner-item-content-padding: 0 3rem 3rem 3rem;
	--banner-item-content-padding-small: 0 1.5rem 1.5rem 1.5rem;
	--banner-slider-item-content-padding: 0 1.5rem 4rem 1.5rem;
	--banner-item-link-border: 1px solid var(--button-background);
	--banner-item-content-color: #fff;
	--banner-item-title-font-size: 2.5rem;
	--banner-item-title-font-size-small: 2.2rem;
	--banner-item-title-line-height: 3.2rem;
	--banner-item-title-line-height-small: 2.4rem;
	--banner-item-title-color: #fff;
	--banner-item-subtitle-font-size: 1.8rem;
	--banner-item-subtitle-font-size-small: 1.6rem;
	--banner-item-subtitle-line-height: 2rem;
	--banner-item-subtitle-color: #fff;
	--banner-item-button-font-size: var(--button-font-size);
	--banner-item-button-padding: 1.5rem 2rem;
	--banner-item-button-padding-small: 1rem 1.5rem;

	@include mobile {
		--banner-item-content-padding: 0 1.5rem 1.5rem 1.5rem;
		--banner-item-title-font-size: var(--banner-item-title-font-size-small);
		--banner-item-title-line-height: var(--banner-item-title-line-height-small);
		--banner-item-subtitle-font-size: var(--banner-item-subtitle-font-size-small);
		--banner-item-button-padding: var(--banner-item-button-padding-small);
		--banner-item-content-font-size: 1.4rem;
		--banner-item-content-line-height: 1.3;
	}

	@include tablet {
		--banner-item-content-padding: 0 1.5rem 1.5rem 1.5rem;
		--banner-slider-item-content-padding: 0 1.5rem 6rem 1.5rem;
		--banner-item-content-font-size: 1.6rem;
		--banner-item-content-line-height: 1.3;
		--banner-item-title-font-size: var(--banner-item-title-font-size-small);
		--banner-item-title-line-height: var(--banner-item-title-line-height-small);
		--banner-item-subtitle-font-size: var(--banner-item-subtitle-font-size-small);
		--banner-item-subtitle-line-height: 1.8rem;
		--banner-item-button-padding: var(--banner-item-button-padding-small);
	}
}

@mixin full-cover-image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
	display: block;
}

.BannerItem {
	display: flex;

	&_isGradient {
		@include bg-image-gradient-fill;

		@include mobile {
			&:after {
				height:75%;
			}
		}
	}

	&-ContentBlock {
		main:not(.CategoryPage_isShop) & {
			padding: var(--banner-item-content-padding);
			z-index: 2;
		}

		.CategoryPage_isShop & {
			display: block;
			position: absolute;
			bottom: 0;
			width: 100%;
		}

		@include mobile {
			text-align: center;
		}

		.BannerItem_isSlider & {
			padding: var(--banner-slider-item-content-padding);
			margin: auto auto 0 auto;
			width: 100%;
			max-width: var(--content-wrapper-width);

			@include mobile {
				text-align: center;
			}
		}

		.BannerItem_isBlock & {
			display: flex;
			flex-direction: column;
			align-self: end;
		}
	}

	&.BannerBlock-Item_type_column_4,
	&.BannerBlock-Item_type_column_5,
	&.BannerBlock-Item_type_column_6 {
		.BannerItem-ContentBlock {
			padding: var(--banner-item-content-padding-small);
		}
	}

	&-Title {
		font-size: var(--banner-item-title-font-size);
		color: var(--banner-item-title-color);
		line-height: var(--banner-item-title-line-height);
		margin: 0 0 1rem 0;

		// Override basic CmsPage class styles
		.CmsPage & {
			font-size: var(--banner-item-title-font-size);
		}

		@include desktop {
			text-align: left;
		}

		@include mobile {
			text-align: center;
		}
	}

	&.BannerBlock-Item_type_column_4,
	&.BannerBlock-Item_type_column_5,
	&.BannerBlock-Item_type_column_6 {
		.BannerItem-Title {
			font-size: var(--banner-item-title-font-size-small);
			line-height: var(--banner-item-title-line-height-small);
		}
	}

	&-Subtitle {
		color: var(--banner-item-subtitle-color);
		font-size: var(--banner-item-subtitle-font-size);
		line-height: var(--banner-item-subtitle-line-height);
		

		.CmsPage & {
			font-size: var(--banner-item-subtitle-font-size);
		}
	}

	h3.BannerItem-Subtitle {
		text-transform: none;
	}

	&.BannerBlock-Item_type_column_4,
	&.BannerBlock-Item_type_column_5,
	&.BannerBlock-Item_type_column_6 {
		.BannerItem-Subtitle {
			font-size: var(--banner-item-subtitle-font-size-small);
		}
	}

	&-Content {
		font-size: var(--banner-item-content-font-size);
		line-height: var(--banner-item-content-line-height);
		color: var(--banner-item-content-color);
		max-width: var(--banner-item-max-content-width);
	}

	&-Link {
		font-size: var(--banner-item-font-size);;
		padding: var(--banner-item-button-padding);
		border: var(--banner-item-link-border);


		@include desktop {
			margin-right: auto;
		}

		@include mobile {
			font-size: 1.4rem;
			width: auto;
			margin: auto;
		}

		svg {
			width: 1.8rem;
			height: auto;
			margin-left: 1.5rem;
		}
	}

	&-ClickableArea {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9;
		display: block;
	}

	&.BannerBlock-Item_type_column_4,
	&.BannerBlock-Item_type_column_5,
	&.BannerBlock-Item_type_column_6 {
		.BannerItem-Link {
			font-size: 1.4rem;
			padding: var(--banner-item-button-padding-small);
		}
	}

	&.BannerBlock-Item_type_column_6 {
		.BannerItem-Link {
			font-size: 1.3rem;
			padding: var(--banner-item-button-padding-small);

			@include narrow-desktop {
			font-size: 1.4rem;
			}
		}
	}

	&-Image,
	&-HoverImage {
		@include full-cover-image;

		&_isGradient:not(img) {
			@include bg-image-gradient-fill;
		}

		&:not(img) {
			> img {
				@include full-cover-image;
			}
		}

		img {
			height: 100%;
		}
	}

	&-HoverImage {
		opacity: 0;
	}

	&_hasHoverImage {
		&:hover {
			.BannerItem-Image {
				opacity: 0;
			}

			.BannerItem-HoverImage {
				opacity: 1;
			}
		}
	}
}