.ProductPage {
    .ProductSectionNavigation {
        .SaasScrollToLink:last-of-type {
            .ProductSectionNavigation-NavItem {
                font-size: 0 !important;

                &::after {
                    content: 'Ook lekker';
                    font-size: 1.6rem;
                }
            }
        }

        &-Items {
            .ProductLinks-Title {
                font-size: 0 !important;

                &::after {
                    content: 'Ook lekker';
                    font-size: 1.8rem;
                    font-weight: bold;
                }
            }
        }
    }

    & .ProductActions {
        display: grid;

        &-AddToCartWrapper {
            order: 4;
        }

        &-Section {
            order: 3;
        }
    }
}