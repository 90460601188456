/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --saas-countdown-offset-top: 0;
    --saas-countdown-item-width: 70px;
    --saas-countdown-label-background: var(--primary-base-color);
    --saas-countdown-label-color: white;
    --saas-countdown-label-font-size: 1rem;
    --saas-countdown-label-line-height: 36px;
    --saas-countdown-sub-title-color: var(--primary-base-color);
    --saas-countdown-sub-title-font-size: var(--saas-countdown-label-font-size);
    --saas-countdown-title-text-transform: uppercase;
    --saas-countdown-title-color: var(--saas-countdown-sub-title-color);
    --saas-countdown-value-background: #a6905c;
    --saas-countdown-value-color: var(--saas-countdown-label-color);
    --saas-countdown-value-font-size: 2.8rem;
    --saas-countdown-value-padding: .5rem;
    --saas-countdown-widget-gap: 1rem;

    @include desktop {
        --saas-countdown-item-width: 110px;
        --saas-countdown-label-font-size: 1.4rem;
        --saas-countdown-value-font-size: 3.6rem;
        --saas-countdown-value-padding: .5rem 2rem;
        --saas-countdown-widget-gap: 3rem;
    }
}

@include desktop {
    @at-root .pageHasSidebar {
        --saas-countdown-offset-top: -161px;
        --saas-countdown-sub-title-color: white;
    }
}

.SaasCountdown {
    display: flex;
    flex-direction: column;
    margin-top: var(--saas-countdown-offset-top);
    margin-bottom: 4rem;

    &-Title {
        text-align: center;

        h2 {
            color: var(--saas-countdown-title-color);
            margin-top: 0;
            text-transform: var(--saas-countdown-title-text-transform);
        }
    }

    &-Widget {
        display: flex;
        gap: var(--saas-countdown-widget-gap);
        justify-content: center;
    }

    &-SubTitle {
        @include mobile {
            display: none;
        }
        
        align-self: center;
        color: var(--saas-countdown-sub-title-color);
        font-weight: bold;
        font-size: var(--saas-countdown-sub-title-font-size);
        font-style: italic;
        left: calc(-1 * var(--saas-countdown-widget-gap));
        text-align: right;
    }

    &-Item {
        display: flex;
        flex-direction: column;
        width: var(--saas-countdown-item-width);

        &:first-of-type {
            margin-left: calc(-1 * var(--saas-countdown-widget-gap));
        }
    }

    &-Value,
    &-Label {
        display: block;
        text-align: center;
        width: var(--saas-countdown-item-width);
    }

    &-Value {
        background: var(--saas-countdown-value-background);
        color: var(--saas-countdown-value-color);
        padding: var(--saas-countdown-value-padding);
        font-size: var(--saas-countdown-value-font-size);
        font-weight: bold;
    }

    &-Label {
        background: var(--saas-countdown-label-background);
        color: var(--saas-countdown-label-color);
        font-size: var(--saas-countdown-label-font-size);
        line-height: var(--saas-countdown-label-line-height);
        padding: 0 1rem;
        text-transform: uppercase;
    }
}