/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-banner-padding: 1rem;
}

.MenuBanner {
    &-Container {
        padding: 0;
        height: 170px;
        margin-bottom: 2rem;

        @include desktop {
            padding: var(--header-menu-banner-padding);
            height: auto;
            margin-bottom: 0;
        }
    }

    &-Main {
        padding: var(--header-menu-banner-padding);
        background-color: var(--primary-base-color);

        &_isImage {
            background-color: transparent;
        }
    }

    &-Item,
    &-Main {
        display: block;
        width: 100%;
        height: 100%;
        @include bg-image-gradient-fill;

        &Title {
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.6rem;
            position: absolute;
            bottom: 15px;
            left: 15px;
            z-index: 2;
        }
    }

    &-Item {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);

        @include desktop {
            border: 8px solid white;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}