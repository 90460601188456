/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --homepage-button-background: #000;
    --homepage-button-color: #fff;
}

.HomepageCategories {
    @include desktop {
        grid-gap: 12px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-block-start: 3px;
    }

    @include mobile {
        display: block;
    }

    &Small {
        @include tablet-portrait {
            grid-template-columns: repeat(2, 3fr);
        }
        
        @include desktop {
            grid-template-columns: repeat(5, 1fr);
        }

        .HomepageCategories-Figure {
            @include desktop {
                height: 270px;
            }
        }
    }

    &Medium {
        grid-template-columns: repeat(3, 1fr);

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &Big {
        grid-template-columns: repeat(2, 1fr);
    }



    &-Figure {
        @include mobile {
            margin-block-start: 24px;
        }

        @include desktop {
            margin-block-start: 5px;
        }

        > .Image {
            border-block-end: 1px solid var(--primary-divider-color);
        }

        .Image {
            display: block;
            height: 0;
            padding-block-end: 84.5%;

            &-Image {
                object-fit: cover;
                border: 1px solid var(--primary-divider-color);
            }
        }
    }

    &-Figcaption {
        --button-color: var(--color-white);
        --button-padding: 40px;
        --hollow-button-background: var(--color-white);
        --hollow-button-hover-background: var(--color-white);

        position: absolute;
        inset-block-end: 24px;
        text-align: center;

        @include desktop {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding-inline: 16px;
        }

        &_isPlaceholder {
            padding-block-end: 31px;
        }

        @include mobile {
            width: 100%;
            padding-inline: 16px;
            inset-block-end: 16px;
        }
    }

    a {
        display: block;
    }
}
