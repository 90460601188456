/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --store-list-content-grid-columns: 1fr;
    --store-list-content-column-gap: 3rem;
    --store-list-content-row-gap: var(--store-list-content-column-gap);

    @include desktop {
        --store-list-content-grid-columns: 1fr 1fr;
        --store-list-content-column-gap: 4rem;
    }
}

.StoreList {
    &-Heading {
        margin-bottom: 1rem;

        h1 {
            font-size: 2.5rem;
            font-weight: 600;
        }
    }

    &-Content {
        display: grid;
        grid-template-columns: var(--store-list-content-grid-columns);
        column-gap: var(--store-list-content-column-gap);
        row-gap: var(--store-list-content-row-gap);
    }
}
