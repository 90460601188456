/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --footer-menu-link-color: var(--footer-column-content-color);
}

.FooterMenu {
    &-ListItem {
        font-size: var(--footer-content-font-size);
        @include reset-list-item;

        margin-bottom: .8rem;

        @include desktop {
            margin-bottom: .5rem;
        }

        > div:first-of-type {
            &:not(.FooterMenu-ListItemContent) {
                font-size: var(--footer-content-font-size);
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }

    &-Link {
        color: var(--footer-menu-link-color);
    }
}
