/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.StoreAddress {
    &-Address,
    &-ContactInfo {
        font-size: 1.6rem;
    }

    &-InfoItem {
        @include reset-list-item;

        line-height: 3rem;
        font-size: 1.6rem;
        display: flex;

        *:nth-child(1) {
            width: 30px;
            align-self: center;
        }
    }
}