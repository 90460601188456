/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

:root {
    --thumbnail-related-product-list-price-label-background-color: var(--primary-base-color);
    --thumbnail-related-product-list-price-label-color: white;
    --thumbnail-related-product-list-swatch-height: 114px;
    --thumbnail-related-product-list-swatch-width: 99px;
    --thumbnail-related-product-list-title-background-color: #f9f9f9;
    --thumbnail-related-product-list: none;
}

// Fix slider
@include mobile {
    .ContentWrapper.ProductPage-Wrapper {
        grid-template-columns: 100%;
    }
}

.ThumbnailRelatedProductList {
    border-bottom: var(--product-visual-bundle-item-title-border);
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    
    &-Title {
        border-bottom: var(--product-visual-bundle-item-title-border);
        font-size: 1.6rem;
        line-height: 1.8rem;
        margin-bottom: 1rem;
        padding: 1.1rem;
        font-weight: 700;
    }

    &-Wrapper {
        align-items: center;

        &_isSlider {
            .swiper-button-prev {
                left: 0;
            }

            .swiper-button-next {
                right: 0;
            }
        }

        &_isStatic {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &-ItemWrapper {
        text-align: center;
    }

    &-Item {
        display: inline-block;
    }

    &-ProductTitle {
        color: var(--paragraph-color);
        max-width: 20rem;
        margin-bottom: 1rem;
    }

    &-PriceLabel {
        line-height: 22px;
        padding: 0 3px;

        .ProductPrice {
            min-height: 0px;
            line-height: normal;
            font-size: 1.4rem;
            padding: 2px;

            del,
            ins {
                display: inline-block;
                font-size: 1.4rem;
            }

            &-PriceValue {
                font-weight: 700;
                margin: 0;
            }

            &-SubPrice {
                display: none;
            }
        }
    }

    &-Image {
        height: var(--thumbnail-related-product-list-swatch-height);
        width: var(--thumbnail-related-product-list-swatch-width);
    
        .Image-Image {
            object-fit: cover;
            object-position: center;
        }
    }
}