.BannerGroup-Container {

    @include desktop { 
        --banner-slider-height: 56rem;
    }

    @include mobile { 
        --banner-slider-height-is-full-width: 40rem;
        --banner-slider-item-content-block-padding-is-full-width: 0 1.5rem 3rem 1.5rem;
    }

    &.ContentWrapper_isFluid {
        @include desktop { 
            .BannerItem {
                &-Title {
                    text-align: left !important;
                }
            }
        }
    }

    .swiper-button-prev, .swiper-button-next {
        top: auto;
        bottom: 3.5rem;
        opacity: 0.8;

        @include mobile { 
            bottom: 2rem;
        }
    }
    .swiper-button-prev {
        left: auto;
        right: 6.5rem;

        @include mobile { 
            left: 40%;
        }
    }

    .swiper-button-next { 
        @include mobile { 
            right: 40%;
        }
    }

    .BannerItem {
        &_isSlider {
            height: var(--banner-slider-height) !important;
            text-align: left;
            
            .BannerItem {
                &-ContentBlock {
                    margin: auto auto 1rem;
                    max-width: var(--content-wrapper-width);

                    @include mobile { 
                        margin-bottom: 5rem;
                    }
                }    
                &-Title {
                    text-align: left;
                    margin-left: 0 !important;
                    font-size: 3rem !important;
                    line-height: 3rem;
                    font-weight: 700;
                    text-shadow: 0px 1px 4px #000000;

                    @include mobile { 
                        text-align: center;
                        font-size: 2.5rem;
                    }
                }

                &-Content {
                    line-height: 2.5rem;
                    font-style: normal;
                    font-size: 1.6rem;
                    margin-bottom: 2rem;
                    margin-top: 2rem;
                    text-shadow: 0px 1px 4px #000000;
                }
                
                &-Link {
                    background-color: #a7ad54;
                    border-radius: 4px;
                    font-weight: 400;
                }
            }
        }
    }
}