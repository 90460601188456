/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-button-color: #222222;
    --header-menu-button-active-color: var(--primary-base-color);
    --header-menu-button-min-height: 8.7rem;

    @include mobile {
        --header-menu-button-min-height: 7.4rem;
    }
}

.MenuButton {
    cursor: pointer;

    @include desktop {
        padding: 0 3rem 0 1.5rem; 
    }

    @include mobile {
        padding: 0 0 0 1.5rem; 
    }

    &_isActive {
        border-color: var(--header-menu-button-active-color);
    }

    &-Inner {
        color: var(--header-menu-button-color);
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 1.7rem;
        display: flex;
        
        @include desktop {
            flex-direction: column;
            font-size: 1.3rem;
        }
        
        @include mobile {
            flex-direction: column-reverse;
            font-size: 1.1rem;
        }

        span {
            background: var(--header-menu-button-color);
            display: block;
            width: 3.2rem;
            height: .2rem;

            &:last-of-type {
                margin-top: .1rem;
            }

            @include desktop {
                width: 3.8rem;

                &:last-of-type {
                    margin-top: .7rem;
                }
            }
            
            + span {
                margin-bottom: .5rem;

                @include desktop {
                    margin-bottom: 0;
                    margin-top: .7rem;
                }
            }
        }
    }
}