/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --event-calendar-border-color: #ddd;
    --event-calendar-date-background-color: black;
    --event-calendar-date-color: #fff;
    --event-calendar-date-offset: 4rem;
    --event-calendar-date-width: 30%;
    --event-calendar-default-padding: 1rem;
    --event-calendar-default-spacing: 2rem;
  }

  
.EventCalendarItem {
    padding-bottom: var(--event-calendar-default-spacing);
    width: calc(100% / 3);
    
    &-Container {
        border: 1px solid var(--event-calendar-border-color);
        display: flex;
        margin-right: var(--event-calendar-default-spacing);
    }

    &-Date {
        background-color: var(--event-calendar-date-background-color);
        color: var(--event-calendar-date-color);
        padding: var(--event-calendar-date-offset) 0;
        text-align: center;
        width: var(--event-calendar-date-width);

        small, span, strong {
            display: block;
        }

        strong {
            font-size: 5rem;
            line-height: 5rem;
            text-transform: uppercase;
        }

        span {
            font-size: 2rem;
            padding: 0.5rem 0;
        }

        small {
            font-size: 1.4rem;
            font-weight: 300;
        }
    }

    &-Details {
        height: 100%;
        width: calc(100% - var(--event-calendar-date-width));
    }

    &-Description {
        border-bottom: 1px solid var(--event-calendar-border-color);
        font-weight: bold;
        text-transform: uppercase;
    }

    &-Link {
        color: #555;
        display: block;
        padding: var(--event-calendar-default-padding);

        &::after {
            content: "\276F";
            position: absolute;
            right: var(--event-calendar-default-padding);
        }
    }

    &-Content {   
        padding : var(--event-calendar-default-padding);
    }
}

.swiper-slide .EventCalendarItem{
    width: 100%;
}