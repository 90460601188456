/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.MostOrdered {
    margin: 0 auto;
    max-width: var(--content-wrapper-width);

    @include mobile {
        padding: 0 1.5rem;
    }

    &-Products {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: max-content;
        grid-gap: 3rem;
        gap: 3rem;

        @include mobile {
            grid-template-columns: 1fr;
        }

        &_type_column_4 {
            grid-template-columns: repeat(4, 1fr);

            @include mobile {
                grid-template-columns: 1fr;
            }
        }

        &_type_column_3 {
            grid-template-columns: repeat(3, 1fr);

            @include mobile {
                grid-template-columns: 1fr;
            }
        }
    }
}