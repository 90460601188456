/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.StoreHours {
    &-List {
        margin: 0;
    }

    &-ListItem {
        @include reset-list-item;
        line-height: 3rem;
        font-size: 1.6rem;
        display: flex;
        justify-content: flex-start;

        span {
            font-weight: bold;
            width: 160px;

            + span {
                font-weight: normal;
            }
        }
    }
}