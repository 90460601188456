.Footer {
    --footer-column-content-background-color: transparent;
    --footer-menu-link-color:#000;

    @include mobile {
        --footer-column-title-font-size: 1.6rem;
        --footer-column-title-text-transform: none;
        --footer-column-newsletter-background-color: var(--secondary-base-color);
    }

    & &-ColumnTitle {
        @include mobile {
            color: var(--footer-column-title-color) !important;
            font-weight: bold;
        }
    }
    
    &-ColumnContent {
        color: inherit;
    }
}

