/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --header-menu-column-border-color: #dddddd;
    --header-menu-list-spacing: 2rem;
    --saas-dropdown-menu-color: var(--dropdown-menu-color);
}

.MenuColumn {
    &-Container {
        border-bottom: 1px solid var(--header-menu-column-border-color);

        @include desktop {
            border-bottom: 0;
        }

        .MenuLink {
            &-Link {
                color: var(--saas-dropdown-menu-color);
            }
        }
    }

    &-List {

        @include mobile {
            padding: 0 1.5rem 1.5rem 1.5rem;
            display: none;
        }

        @include desktop {
            margin-right: var(--header-menu-list-spacing);
            margin-bottom: 1rem;
        }

        &_type_button {
            li:last-of-type {
                margin-bottom: 1rem;
            }
        }

        &_isActive {
            @include mobile {
                display: block;
            }
        }
    }

    &-Title {
        margin-bottom: .4rem;
        font-size: 1.6rem;
        font-weight: bold;
    }

    &-ListItem {
        @include reset-list-item;
        color: var(--saas-dropdown-menu-color);

        line-height: 3rem;
        font-size: 1.5rem;

        &:last-of-type {
            margin: 0;
        }

        @include desktop {
            font-size: 1.4rem;
        }

        .Button_isSmall {
            padding: .8rem 1.4rem;
            font-size: 1.2rem;
        }
    }
}
