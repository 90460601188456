/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.SaasSlider {
	&-Container {
		width: 100%;
		height: auto;
	}
}

.swiper-button {
	&-prev, &-next {
		margin: 0;
		width: 3rem;
		height: 3rem;

		@include desktop {
			top: 50%;
			transform: translateY(-50%);
		}

		@include mobile {
			top: auto;
			bottom: 2rem;
		}

		&:after {
			content: '';
			background-size: cover;
			background-position: center;
			color: transparent;
			color: white;
			width: 3rem;
			height: 3rem;
		}

		&.swiper-button-disabled {
			pointer-events: initial;
		}
	}

	&-prev {
		&:after {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NyIgaGVpZ2h0PSI0NyIgdmlld0JveD0iMCAwIDQ3IDQ3Ij4KICA8ZyBpZD0iYXJyb3ctbGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+CiAgICA8ZyBpZD0iUmVjdGFuZ2xlXzgwIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA4MCIgZmlsbD0icmdiYSgwLDAsMCwwLjM5KSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEiPgogICAgICA8cmVjdCB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHJ4PSIyMi41IiBzdHJva2U9Im5vbmUiLz4KICAgICAgPHJlY3QgeD0iLTAuNSIgeT0iLTAuNSIgd2lkdGg9IjQ2IiBoZWlnaHQ9IjQ2IiByeD0iMjMiIGZpbGw9Im5vbmUiLz4KICAgIDwvZz4KICAgIDxwYXRoIGlkPSJjaGV2cm9uLXJpZ2h0LWxpZ2h0IiBkPSJNMTYuOTgsMzMuMWwuMzA3LjMwN2EuNTIuNTIsMCwwLDEsMCwuNzM2TDguODA5LDQyLjYyMywxNy4yODcsNTEuMWEuNTIuNTIsMCwwLDEsMCwuNzM2bC0uMzA3LjMwN2EuNTIuNTIsMCwwLDEtLjczNiwwTDcuMDkyLDQyLjk5MWEuNTIuNTIsMCwwLDEsMC0uNzM2TDE2LjI0NCwzMy4xQS41Mi41MiwwLDAsMSwxNi45OCwzMy4xWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuMDYxIC0xOC43MDcpIiBmaWxsPSIjZmZmIi8+CiAgPC9nPgo8L3N2Zz4K");
		}

		@include desktop {
			left: 1.5rem;
		}

		@include mobile {
			left: calc(50% - 3.75rem);
			right: auto;
		}
	}

	&-next {
		&:after {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NyIgaGVpZ2h0PSI0NyIgdmlld0JveD0iMCAwIDQ3IDQ3Ij4KICA8ZyBpZD0iYXJyb3ctcmlnaHQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMSkiPgogICAgPGcgaWQ9IlJlY3RhbmdsZV84MCIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgODAiIGZpbGw9InJnYmEoMCwwLDAsMC4zOSkiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxIj4KICAgICAgPHJlY3Qgd2lkdGg9IjQ1IiBoZWlnaHQ9IjQ1IiByeD0iMjIuNSIgc3Ryb2tlPSJub25lIi8+CiAgICAgIDxyZWN0IHg9Ii0wLjUiIHk9Ii0wLjUiIHdpZHRoPSI0NiIgaGVpZ2h0PSI0NiIgcng9IjIzIiBmaWxsPSJub25lIi8+CiAgICA8L2c+CiAgICA8cGF0aCBpZD0iY2hldnJvbi1yaWdodC1saWdodCIgZD0iTTcuNCwzMy4xbC0uMzA3LjMwN2EuNTIuNTIsMCwwLDAsMCwuNzM2bDguNDc4LDguNDc4TDcuMDkyLDUxLjFhLjUyLjUyLDAsMCwwLDAsLjczNmwuMzA3LjMwN2EuNTIuNTIsMCwwLDAsLjczNiwwbDkuMTUzLTkuMTUzYS41Mi41MiwwLDAsMCwwLS43MzZMOC4xMzQsMzMuMUEuNTIuNTIsMCwwLDAsNy40LDMzLjFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC41NiAtMTguNzA3KSIgZmlsbD0iI2ZmZiIvPgogIDwvZz4KPC9zdmc+Cg==");
		}

		@include desktop {
			right: 1.5rem;
		}

		@include mobile {
			right: calc(50% - 3.75rem);
			left: auto;
		}
	}
}