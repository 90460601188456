/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    
    --category-header-title-font-size: 3.5rem;
    --category-header-title-font-style: normal;
    --category-header-title-text-color: white;
    --category-header-sub-title-font-size: 2rem;
    --category-header-sub-title-font-style: italic;
    --category-header-sub-title-text-color: white;
    --category-header-content-font-size: 1.4rem;
    --category-header-content-font-style: italic;
    --category-header-content-text-color: white;
    --category-header-product-background-color: #ececec;
    --category-header-product-card-padding: 3rem;

    @include desktop {
        --category-image-height: 50rem;
    }

    @include mobile {
        --category-image-height: 30rem;
        --category-header-title-font-size: 2.8rem;
    }
}

.PageHeader {
    @include mobile {
        margin-top: 2rem;
    }

    &-Image {
        padding-bottom: var(--category-image-height);
        @include bg-image-gradient-fill;

        .Image-Image {
            object-fit: cover;
        }
    }

    &-ContentWrapper {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        right: 0;
        overflow: hidden;
        max-width: var(--content-wrapper-width);
        width: 100%;
        

        @include desktop {
            z-index: 30;
        }

        @include mobile {
            bottom: unset;
            top: 0;
            left: 0;
            width: 100%;
            height: var(--category-image-height);
            transform: unset;
            z-index: 1;
        }
    }

    &:after {  
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        background: linear-gradient(0deg,#000,transparent);
        z-index: 1;

        @include desktop {
            height: 50%;
            bottom: 0;
        }

        @include mobile {
            height: 30%;
            bottom: 0;
        }  
    }

    &-DetailImage {
        padding: 2rem;
        border: 1px solid #aaaaaa;
        max-width: 220px;
        max-height: 220px;
        margin-bottom: 2rem;
        background: rgba(0, 0, 0, .5);
        border-radius: 3px;

        @include mobile {
            margin: 0 auto 2rem auto;
        }
    }

    &-DetailPicture {
        align-self: center;
        padding-bottom: 80%;

        img {
            object-position: center;
        }
    }

    &-Content {
        width: 100%;
        max-width: 50%;
        margin: 0;
        padding-bottom: 20px;
        
        @include mobile {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            max-width: 100%;
        }
    }

    &-ContentText {
        color: var(--category-header-content-text-color);
        display: flex;
        flex-direction: column;
        font-size: var(--category-header-content-font-size);
        justify-content: flex-end;
        padding-bottom: 2rem;

        @include mobile {
            justify-content: unset;
            padding-bottom: 0;
        }

        h1 {
            margin-bottom: 1rem;
            font-size: var(--category-header-title-font-size);
            font-style: var(--category-header-title-font-style);
            color: var(--category-header-title-text-color);
        }

        h2 {
            margin: 0;
            text-transform: none;
            font-size: var(--category-header-sub-title-font-size);
            line-height: 3.2rem;
            font-style: var(--category-header-sub-title-font-style);
            color: var(--category-header-sub-title-text-color);
        }

        p {
            font-style: var(--category-header-content-font-style);
            font-size: var(--category-header-content-font-size);
            color: var(--category-header-content-text-color);
            margin-bottom: 0;
        }
    }

    &-Button {
        align-self: flex-start;

        --hollow-button-border: white;
        --hollow-button-color: white;
    }

    &-WidgetWrapper {
        max-width: var(--content-wrapper-width);
        width: 100%;
        position: absolute;
        bottom: 0.2rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: flex-end;

        @include mobile {
            position: relative;
        }

        @include desktop {
            &_isProduct {
                z-index: 40;
            }
        }

        @include mobile {
            &_isProduct {
                z-index: 2;
            }
        }
    }

    &-ContentProduct {
        max-width: 580px;
        width: 100%;
        max-height: 330px;
        height: 100%;
        background-color: var(--category-header-product-background-color);

        @include mobile {
            max-width: 100%;
        }

        &_isBlur {
            background-size: 100%;
            backdrop-filter: blur(8px);
            background-color: rgba(255, 255, 255, 0.2);
        }

        .ProductCard {
            --product-card-price-font-size: 3rem;
            padding: var(--category-header-product-card-padding);

            @include mobile {
                box-shadow: none;
            }

            &-Link {
                max-width: 200px;
            }

            &-Content {
                max-width: 100%;

                .ProductActions {
                    &-AddToCart {
                        width: fit-content;
                    }
                }
            }

            &-Picture {
                padding-bottom: 100%;
            }

            .SaasProductTitleBlock {
                @include desktop {
                    margin-top: 30px;
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
            &-FigureReview {
                background: transparent;
            }

            &_listItemView_row {
                .ProductCard {
                    &-Content {
                        background: transparent;

                        @include mobile {
                            background: transparent;
                        }
                    }    

                    &-AddToWrapper {
                        @include mobile {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }

        .ProductPrice,
        .ProductCard-Content {
            color: white;

            &-HighPrice {
                color: white;
            }
        }
    }
}