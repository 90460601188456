/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --store-list-item-content-border-color: #efefef;
    --store-list-item-content-border: 1px solid var(--store-list-item-content-border-color);
    --store-list-item-content-padding: 2rem;
}

.StoreListItem {
    padding: calc(var(--store-list-item-content-padding)/2) calc(var(--store-list-item-content-padding)/4);
    margin-bottom: 0;
    list-style: none !important;
    &::before {
        content: none;
    }
    &.StoreListItem_layoutType_list_without_logos:nth-child(odd) {
        background: #F9F9F9;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 270px auto;

        @include mobile {
            display: block;
        }
    }

    &-Figure {
        display: flex;
        align-items: stretch;
    }

    &-Thumbnail {
        align-self: center;
        height: 100%;

        .Image-Image {
            object-fit: cover;
        }
    }

    &-Link {
        display: block;
        flex: 1;
    }

    &-Content {
        border: var(--store-list-item-content-border);

        .Button {
            &_isDefault {
                cursor: default;
            }
        }
    }

    &-ContentHeading {
        padding: var(--store-list-item-content-padding);
        border-bottom: 1px solid var(--store-list-item-content-border-color);

        h3 {
            font-size: 1.6rem;
            margin: 0;
        }
    }

    &-AddressInfo {
        padding: var(--store-list-item-content-padding);
    }

    &-ButtonWrapper {
        padding: var(--store-list-item-content-padding);
        padding-top: 0;
    }

    &-Placeholder {
        height: 100%;
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        max-width: 270px;
    }

    &_layoutType_list_without_logos {
        .StoreListItem{
            &-Wrapper {
                display: block;
            }

            &-Link {
                flex: initial;
            }

            &-Content {
                border: none;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
            }

            &-ContentHeading {
                padding: 0;
                border-bottom: none;

                h3 {
                    font-size: 1.9rem;
                    margin: 0;

                    @include mobile {
                        font-size: 1.6rem;
                    }
                }
            }

            &-AddressInfo {
                padding: calc(var(--store-list-item-content-padding)/4) 0 0;
            }

            &-ButtonWrapper {
                padding: 0;
                min-width: 115.25px;
                max-width: 115.25px;

                button.Button {
                    font-size: 1.2rem;
                    --button-background: var(--primary-base-color);
                }
            }
        }
    }
}
