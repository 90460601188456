/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --content-block-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    --content-block-title-padding: 2rem 0;
    --content-block-title-background-color: var(--secondary-light-color);
    --content-block-title-border-color: var(--secodary-dark-color);
    --content-block-title-text-transform: normal;
    --content-block-title-font-size: 1.6rem;
    --content-block-content-padding: 2rem;

    @include desktop {
        --content-block-title-font-size: 2.5rem;
    }
}

.ContentBlock {
    box-shadow: var(--content-block-box-shadow);
    height: 100%;
    display: flex;
    flex-direction: column;

    &-Title {
        padding: var(--content-block-title-padding);
        border-bottom: 1px solid var(--content-block-title-border-color);

        h2 {
            margin: 0;
            text-transform: var(--content-block-title-text-transform);
            font-size: var(--content-block-title-font-size);
        }

        &_hasLink {
            display: flex;

            > a {
                margin-left: auto;
                padding: .3rem .6rem;
                background: white;
            }
        }
    }

    &-Content {
        padding: var(--content-block-content-padding);
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}