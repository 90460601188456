/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

:root {
    --complete-order-product-card-border: 1px solid #DDD;
    --complete-order-product-card-qty-height: 41px;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.CompleteOrderProductCard {
    border-bottom: none;

    &:hover {
        box-shadow: none;
    }

    &.ProductCard_layout_list {
        box-shadow: none;
        border: {
            top: var(--complete-order-product-card-border);
            left: var(--complete-order-product-card-border);
            right: var(--complete-order-product-card-border);
        }
        margin-bottom: 0;
        text-align: left;

        &:last-child {
            border-bottom: var(--complete-order-product-card-border);
            margin-bottom: 0;
        }

        .ProductCard {
            &-AttributeWrapper {
                z-index: 10;

                &.animate {
                    animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
                }
            }

            &-CompleteOrderActions {
                position: absolute;
                top: 50%;
                transform: translate(50%, -50%);

                @include desktop {
                    right: 0;
                }

                @include mobile {
                    right: 1rem;
                }
            }

            &-Content {
                max-width: 420px;
                position: static;

                &_layout_list {
                    margin-left: 10px;
                }
            }

            &-FigureReview {
                min-width: 20%;
            }

            &-Link {
                flex-direction: row;
                position: static;
            }

            &-Name {
                font-size: 16px;
                font-weight: bold;
                margin: 5px 0 0 0;
            }

            &-PriceWrapper {
                margin-bottom: 0;
                text-align: left;
            }

            &-Price {
                align-items: center;
                display: flex;
                font-size: 20px;
            }

            &-Qty {
                height: var(--complete-order-product-card-qty-height);
                margin-right: 10px;
            }
        }

        .ProductPrice {
            &-Price {
                align-items: center;
            }

            &-PriceValue {
                margin-top: 0;
            }
        }
    }

    &-Button {
        &_isSelected {
            background-color: var(--primary-success-color);
        }

        &:hover {
            @include desktop {
                background-color: var(--primary-success-color);
            }
        }
        
        background-color: var(--imported_primary_base_color);
        border-radius: 50%;
        display: flex;
        cursor:pointer;
        align-items: center;
        justify-content: center;
        color: #FFF;
        

        @include desktop {
            font-size: 1.8rem;
            height: 5rem;
            width: 5rem;
        }

        @include mobile {
            font-size: 1.6rem;
            height: 4em;
            max-height: 4rem;
            width: 4rem;
        }
    }

    &-QtyPriceWrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        z-index: 9;
    }

    .ProductConfigurableAttributeDropdown {
        margin-top: 10px;

        @include desktop {
            width: 100%;
        }
    }
}