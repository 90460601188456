/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  --homepage-info-block-title-background-color: #F9F9F9;
  --homepage-border: 1px solid #EEE;
}

.HomePage {
  .CmsPage {
      &,
      &-Wrapper {
          /**
          * TODO: Remove #html-body selector fix when page-builder is used
          */
          #html-body & {
            padding: 0;
            margin-block-end: 0;
            max-width: none;
          }
      }
  }
}

.HomePageInfo {
  &-Wrapper {
    height: 100%;
  }

  &-BlockTitle {
    display: flex;
    background-color: var(--homepage-info-block-title-background-color);
    padding: 0 2rem;
    align-items: center;
    min-height: 5rem;
    font-weight: 700;

    h2 {
      font-size: 1.6rem;
      margin: 0;
    }
  }

  &-BlockContent {
    border: var(--homepage-border);
    border-top: none;
    padding: 1.5rem 2rem;
    height: calc(100% - 5rem);

    ul {
      padding-left: 0;

      li {
        display: flex;

        span {
          width: 50%;

          &:first-of-type {
            padding-right: 0.5rem;
            font-weight: 700;
          }
        }
      }
    }

    .phone {
      border-top: var(--homepage-border);
      padding-top: 1rem;
    }
  }
}
